import React from 'react';
import Accordion from "../../../../components/accordion/accordion";


const StoreFAQ = ({primary}) => {
  return (
    <>
      <section style={{minHeight: '70vh', alignContent: 'center', display: "grid", justifyContent: "center", textAlign: "center", gap: "30px"}}>
        <h4 className="app-h4">FAQ</h4>
        <Accordion
          active={true}
          title={"Is this app secure?"}
          content={"Yes, our app is completely secure and has undergone thorough testing to ensure a safe user experience."}
          customColor={primary}
        />
        <Accordion
          active={true}
          title={"Why can't I download the APK?"}
          content={"To download the APK, you may need to enable the option to download from web sources in your device's settings. This is a security measure to protect your device, but rest assured our APK is safe to download."}
          customColor={primary}
        />
        <Accordion
          active={true}
          title={"Does this app work for iPhone?"}
          content={"No, our app is currently not compatible with iPhones. It is designed for Android devices."}
          customColor={primary}
        />
        <Accordion
          active={true}
          title={"I received a Play Protect message. Is this normal?"}
          content={"Yes, it's normal to receive a Play Protect message if you're downloading our app from a source other than the official Google Play Store. This is because our app is not available on the Play Store. However, please be assured that our app is completely safe and has been thoroughly tested for security."}
          customColor={primary}
        />
      </section>
    </>
  );
};

export default StoreFAQ;



