import React, { useEffect } from 'react';
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import LoadingComponent from 'components/ui/loading/loading-component';
import { withTranslation } from 'react-i18next';
import ToastHelper from "../../utils/helpers/toast-helper";
import NotificationService from "../../service/notification-service";
import { getI18n } from "react-i18next";
import Dropdown from 'react-dropdown';
import NotificationHelper from "../../utils/helpers/notification-helper";

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      message: '',
      image: '',
      loadingSubmit: false,
      showId: false,
      action: 'NONE',
      actionText: '',
      actionValue: ''
    };
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;
    const I18n = getI18n()

    let pageData = <LoadingComponent />;
    if (!loading) {
      pageData = (
        <>

          <div className="group-forms">

            <form className="form" onSubmit={(event) => this.sendNotification(event)}>
              <p className="page-title">{t('notification.page_title')}</p>

              <div className="input-container">

                {/* Only for one */}
                <div className="two-col" onClick={this.handleShowIdChange} style={{ marginBottom: "30px" }}>
                  <label>{t('notification.only_for_one')}</label>
                  <div >
                    {this.state.showId ? (
                      <i className="bi bi-toggle-on" />
                    ) :
                      <i className="bi bi-toggle-off" />
                    }
                  </div>
                </div>

                {this.state.showId && (

                  <div >
                    {/* Collateral ID */}
                    <label className="label">{I18n.t('notification.id')}</label>
                    <input
                      type="text"
                      value={this.state.id}
                      maxLength={20}
                      className="form-input"
                      onChange={(event) =>
                        this.setState({
                          id: event.target.value
                        })
                      }
                    />
                  </div>

                )}
              </div>


              {/* TITLE */}
              <label className="label">{I18n.t('notification.title')}</label>
              <input
                type="text"
                value={this.state.title}
                maxLength={80}
                className="form-input"
                onChange={(event) =>
                  this.setState({
                    title: event.target.value
                  })
                }
              />

              {/* MESSAGE */}
              <label className="label">{I18n.t('notification.message')}</label>
              <input
                type="text"
                value={this.state.message}
                maxLength={200}
                className="form-input"
                onChange={(event) =>
                  this.setState({
                    message: event.target.value
                  })
                }
              />

              {/* IMAGE */}
              <label className="label">{I18n.t('notification.image')}</label>
              <input
                type="text"
                value={this.state.image}
                className="form-input"
                onChange={(event) =>
                  this.setState({
                    image: event.target.value
                  })
                }
              />

              {/* ACTIONS */}
              <label>{t('notification.action')}</label>
              <Dropdown
                className="form-select-without-margin-horizontal"
                options={NotificationHelper.actions()}
                onChange={this.handleActionChange}
                value={this.state.action} />

              {this.state.action != 'NONE' && (

                <div >
                  {/* Action Text */}
                  <label className="label">{I18n.t('notification.action_text')}</label>
                  <input
                    type="text"
                    value={this.state.actionText}
                    maxLength={20}
                    className="form-input"
                    onChange={(event) =>
                      this.setState({
                        actionText: event.target.value
                      })
                    }
                  />

                  {/* Action Value */}
                  <label className="label">{I18n.t('notification.action_value')}</label>
                  <input
                    type="text"
                    value={this.state.actionValue}
                    className="form-input"
                    onChange={(event) =>
                      this.setState({
                        actionValue: event.target.value
                      })
                    }
                  />

                </div>

              )}

              <button
                type="submit"
                className="form-button"
              >
                {t('notification.send')}
                {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
              </button>
            </form>

          </div>
        </>
      );
    }
    return pageData;
  }

  sendNotification = (event) => {

    const { t } = this.props;

    event.preventDefault()

    if (this.state.showId && !this.state.id) {
      ToastHelper.showWarning(t('toast_helper.valid_notification_id'));
      return;
    }

    if (!this.state.title) {
      ToastHelper.showWarning(t('toast_helper.valid_notification_title'));
      return;
    }

    if (!this.state.message) {
      ToastHelper.showWarning(t('toast_helper.valid_notification_message'));
      return;
    }

    let actions = null;
    if (this.state.action != 'NONE') {
      if (!this.state.actionText) {
        ToastHelper.showWarning(t('toast_helper.valid_notification_action_text'));
        return;
      }

      if (!this.state.actionValue) {
        ToastHelper.showWarning(t('toast_helper.valid_notification_action_value'));
        return;
      }

      actions = [{
        text: this.state.actionText,
        value: (this.isURL(this.state.actionValue)) ? encodeURIComponent(this.state.actionValue) : this.state.actionValue,
        action: this.state.action
      }]

    }

    const notification = {
      "id": this.state.id,
      "title": this.state.title,
      "message": this.state.message,
      "image": this.state.image,
      "actions": actions
    }

    ToastHelper.showLoading(NotificationService.send(notification), {
      pending: t('toast_helper.notification_sending_wait'),
      success: t('toast_helper.notification_sent_success'),
      error: {
        message: t('toast_helper.notification_sending_error'),
        notFound: t('toast_helper.not_found'),
      },
    })
      .then(response => {
        this.setState({
          collateral: response.data,
          littleLoading: '',
          showConfirmation: '',
        });

        if (response) {
          this.setState({
            id: '',
            title: '',
            message: '',
            image: '',
            action: 'NONE',
            actionText: '',
            actionValue: '',
            showId: false
          });
        }
      })
      .catch(e => {
        this.setState({
          littleLoading: '',
        })
      });
  }

  handleShowIdChange = (event) => {

    let isChecked = !this.state.showId

    this.setState({ showId: isChecked });

    if (!isChecked) {
      this.setState({ id: "" });
    }
  }

  handleActionChange = (event) => {

    let actionLabel = (event.value == 'NONE') ? '' : event.label
    this.setState({
      action: event.value,
      actionText: actionLabel
    });
  };

  isURL(text) {
    try {
      new URL(text);
      return true;
    } catch (error) {
      return false;
    }
  }
}

const NotificationTranslated = withTranslation()(Notification);

export default NotificationTranslated;
