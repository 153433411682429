import React from 'react';
import { withTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import ToastHelper from '../../../../utils/helpers/toast-helper';
import { t } from 'i18next';
import KnoxGuardBulkActionsService from '../../../../service/knox-guard-bulk-actions-service';

class KnoxReleaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      isFileReady: false
    };
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({selectedFile: file, isFileReady: true});
  }

  handleDownloadTemplate = async () => {
    ToastHelper.showLoading(
      KnoxGuardBulkActionsService.getTemplate('release'),
      {
        error: {
          message: t('toast_helper.try_later'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(response => {

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'template_release_device.csv');
        link.click();

        window.URL.revokeObjectURL(url);

      })
      .catch(e => {
        console.log(e)
      });
  }

  handleConfirm = async () => {
    this.setState({ isButtonDisabled: true });

    let selectedFile = this.state.selectedFile;
    const params = new FormData();
    params.append('file', selectedFile);

    ToastHelper.showLoading(
      KnoxGuardBulkActionsService.submitAction('release', params),
      {
        error: {
          message: t('toast_helper.try_later'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(_ => {
        setTimeout(() => {
          this.setState({ isButtonDisabled: false });
        }, 5000);
      })
      .catch(_ => {
        this.setState({ isButtonDisabled: false });
      });
  }

  render() {
    const {t} = this.props;
    return (
      <>
        <section className="settings-forms-container">
          <div className="input-container">
            <label style={{ fontSize: '16px' }}>{t('bulk_actions.release_imei_text')}</label>
            <br />
            <small className="text-danger" style={{ color: 'red', fontSize: '13px' }}>
              {t('bulk_actions.imei_limit_text')}
            </small>
            <div style={{ margin: '10px 0', fontSize: '13px' }}>
              <div>
                <strong>{t('bulk_actions.required_parameters')}:</strong>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  <li>IMEI {t('bulk_actions.or')} User ID - {t('bulk_actions.required')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-3" style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="fileInputRelease"
                     style={{
                       backgroundColor: '#007bff',
                       color: 'white',
                       padding: '5px 10px',
                       borderRadius: '4px',
                       cursor: 'pointer',
                       marginRight: '15px'
                     }}>
                {t('bulk_actions.upload_file')}
              </label>
              <input
                type="file"
                id="fileInputRelease"
                accept=".csv"
                onChange={this.handleFileChange}
                style={{ display: 'none' }}
              />

              {this.state.selectedFile && (
                <div className='alert alert-success'
                     role="alert"
                     style={{
                       margin: '0',
                       padding: '5px 10px',
                       borderRadius: '4px',
                       maxWidth: '550px',
                       whiteSpace: 'nowrap',
                       overflow: 'hidden',
                       textOverflow: 'ellipsis'
                     }}>
                  {t('bulk_actions.file')}: {this.state.selectedFile.name}
                </div>
              )}
            </div>

            <div style={{
              width: '100%',
              borderTop: '1px solid #ccc',
              marginTop: '20px',
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <button
                onClick={this.handleDownloadTemplate}
                style={{
                  backgroundColor: '#6c757d',
                  color: 'white',
                  padding: '8px 12px',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}>
                {t('bulk_actions.download_template')}
              </button>

              {this.state.isFileReady && (
                <button
                  onClick={this.handleConfirm}
                  disabled={this.state.isButtonDisabled}
                  style={{
                    backgroundColor: '#006400',
                    color: 'white',
                    padding: '8px 12px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}>
                  {this.state.isButtonDisabled ? t('bulk_actions.waiting') : t('bulk_actions.confirm')}
                </button>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const KnoxReleaseFormTranslated = withTranslation()(KnoxReleaseForm)

export default KnoxReleaseFormTranslated
