import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class AppWebhookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webhook: props.value,
      webhookAux: props.value,
      showWebhook: (props.value != null && props.value.length > 0)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col" onClick={this.handleShowWebhookChange}>
          <label>{t('custom.webhook_label')}</label>
          <div >
            {this.state.showWebhook ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        {this.state.showWebhook ? (

          <input
            type="text"
            className="form-input"
            value={this.state.webhook}
            placeholder={t('custom.webhook_place_holder')}
            onChange={this.handleWebhookChange}
          />
      
        ) :
          <div style={{ marginTop: "30px", marginBottom: "30px" }} />
        }
      </div>
    );
  }

  handleWebhookChange = (event) => {
    this.setState({
      webhook: event.target.value,
      webhookAux: event.target.value,
    });
    this.props.handle(event.target.value)
  };

  handleShowWebhookChange = (event) => {

    let isChecked = !this.state.showWebhook

    this.setState({ showWebhook: isChecked });

    if(isChecked) {
      this.setState({ webhook: this.state.webhookAux });
      this.props.handle(this.state.webhookAux)
    } else {
      this.setState({ webhook: "" });
      this.props.handle("")
    }
  }
}

const AppWebhookFormTranslated = withTranslation()(AppWebhookForm)

export default AppWebhookFormTranslated
