import React, { useState } from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import { getI18n } from "react-i18next";
import AppPushForm from '../../partner/app-push-form';

function AppPush({ step, setStep, updateFormData, formData }) {
  const I18n = getI18n()

  function handlePushChange(push) {
    updateFormData("push", push);
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual">
        <div style={{ width: '450px' }}>

          {/* Push */}
          <AppPushForm
            value={formData.push}
            handle={handlePushChange} />

        </div>
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />
        <NextButton step={step} setStep={setStep} />
      </section>
    </div>
  );
}

export default AppPush;
