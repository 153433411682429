import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';
import PartnerService from "../../../../service/partner-config-service";

class AppVersionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: props.version ?? {},
      versions: props.versions ?? [""],
      versionStartsWith: props.versionStartsWith,
      title: props.title,
      percents: ["10", "25", "50", "75", "100"]
    };
  }

  async componentDidMount() {
    let versions = await fetchData(this.props.versionStartsWith)
    this.setState({
      versions: versions,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <p>{this.state.title}</p>

        <div style={{ marginTop: "30px", marginBottom: "30px" }} />

        <label>{t('custom.old_version')}</label>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={this.state.versions ?? [""]}
          onChange={this.handleOldVersionChange}
          value={this.state.version.oldApkVersion} />


        <label>{t('custom.new_version')}</label>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={this.state.versions ?? [""]}
          onChange={this.handleNewVersionChange}
          value={this.state.version.newApkVersion} />


        <label>{t('custom.percent_share_of_updates')}</label>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={this.state.percents}
          onChange={this.handlePercentShareOfUpdatesVersionChange}
          value={this.state.version.percentShareOfUpdates?.toString()} />

      </div>
    );
  }

  handleNewVersionChange = (event) => {

    let v = this.state.version
    v.newApkVersion = event.value

    this.setState({
      version: v,
    });

    this.props.handle(v)
  };

  handleOldVersionChange = (event) => {

    let v = this.state.version
    v.oldApkVersion = event.value

    this.setState({
      version: v,
    });

    this.props.handle(v)
  };


  handlePercentShareOfUpdatesVersionChange = (event) => {

    let v = this.state.version
    v.percentShareOfUpdates = parseInt(event.value)

    this.setState({
      version: v,
    });

    this.props.handle(v)
  };
}

const fetchData = async (versionStartsWith) => {
  try {
    let data = (await PartnerService.getCompatibleVersions()).data;
    return data.filter(version => version.startsWith(versionStartsWith)).sort().reverse();
  } catch (error) {
    console.error(error);
  }
};

const AppVersionFormTranslated = withTranslation()(AppVersionForm)

export default AppVersionFormTranslated
