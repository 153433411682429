import React from 'react';

function NextButton({ step, setStep }) {
  const handleNextStep = () => {
    setStep(step + 1);
  };

  return (
    <button className="outline-btn" onClick={handleNextStep}>Next</button>
  );
}

export default NextButton;
