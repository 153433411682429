import React from 'react';
import LoadingComponent from 'components/ui/loading/loading-component';
import {withTranslation} from "react-i18next";
import CollateralsTable from "../../components/collaterals-table/collaterals-table";
import PartnersTableTranslated from "../../components/tables/partners-table";
import routes from "../../navigation/routes";
import {Link, NavLink} from "react-router-dom";

class AllPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {loading} = this.state;
    const { t } = this.props;

    if (loading) return <LoadingComponent/>
    else {
      return <>
        <div className="demo-container">
          <header style={{display: 'Flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <p className="page-title">{t('partners.title')}</p>
            <Link to={routes.NEW_PARTNER}>
              <button className="short-button">New partner</button>
            </Link>
          </header>
          <PartnersTableTranslated/>
        </div>
      </>
    }
  }
}

const AllPartnersTranslated = withTranslation()(AllPartners);

export default AllPartnersTranslated;
