import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import RegenerateApiKeyDialogSuccess from "components/ui/modal/regenerate-api-key-dialog-success";
import ToastHelper from 'utils/helpers/toast-helper';
import PartnerConfigService from '../../../../service/partner-config-service';

class CredentialsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regenerateApiKeyButtonClickHandler: props.regenerateApiKeyButtonClickHandler,
      loadingSubmit: false,
      showRegenerateApiKeyDialogSuccess: false,
      newClientId: null,
      newClientSecret: null,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div>
          <p className="accordion-title">{t('custom.endpoint_description')}</p>
          <div className="center-all">
            <button
              type="submit"
              className="short-button"
              onClick={this.regenerateApiKey}
            >
              {t('custom.generate_new_api_key')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>
          </div>

          <RegenerateApiKeyDialogSuccess
            isOpen={this.state.showRegenerateApiKeyDialogSuccess}
            confirmClickHandler={this.hideRegenerateApiKeyDialogSuccess}
            clientId={this.state.newClientId}
            clientSecret={this.state.newClientSecret}
          />

        </div>
      </div>
    );
  }

  regenerateApiKey = async () => {

    const { t } = this.props;

    this.setState({ loadingSubmit: true });

    ToastHelper.showLoading(
      PartnerConfigService.regenerateApiKey(),
      {
        error: {
          message: t('toast_helper.generate_new_api_key_error'),
          notFound: t('toast_helper.partner_not_found'),
        },
        success: t('toast_helper.generate_new_api_key_success'),
        pending: t('toast_helper.generate_new_api_key_wait'),
      })
      .then(response => {
        this.setState({ loadingSubmit: false });

        if (response) {
          this.setState({
            newClientId: atob(response.data.temp?.userDetails?.clientId),
            newClientSecret: atob(response.data.temp?.userDetails?.clientSecret),
            showRegenerateApiKeyDialogSuccess: true
          });
        }
      })
      .catch(e => {
        console.log(e)
        this.setState({ loadingSubmit: false });
      });
  };

  hideRegenerateApiKeyDialogSuccess = () => {
    this.setState({
      showRegenerateApiKeyDialogSuccess: false,
      newClientId: null,
      newClientSecret: null
    });
  };
}

const CredentialsFormTranslated = withTranslation()(CredentialsForm)

export default CredentialsFormTranslated
