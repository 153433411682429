import React, {useState} from 'react';
import RenderGuide from "./render-guide";
import images from "./data/images/images"
import instructions from "./data/text/instructions"

function InstallationGuide() {

  const [activeTab, setActiveTab] = useState("samsung");

  return (
    <div>
      {/* Tab nav */}
      <ul className="store-tab-menu">
        <li className={activeTab === "samsung" ? "tab-active" : ""} onClick={() => setActiveTab('samsung')}>Samsung</li>
        <li className={activeTab === "xiaomi" ? "tab-active" : ""} onClick={() => setActiveTab('xiaomi')}>Xiaomi</li>
        <li className={activeTab === "motorola" ? "tab-active" : ""} onClick={() => setActiveTab('motorola')}>Motorola
        </li>
        <li className={activeTab === "lg" ? "tab-active" : ""} onClick={() => setActiveTab('lg')}>LG</li>
      </ul>
      <div className="outlet">
        {(() => {
          switch (activeTab) {
            case 'samsung':
              return <RenderGuide images={images.samsung} instructions={instructions.samsung}/>
            case 'xiaomi':
              return <RenderGuide images={images.xiaomi} instructions={instructions.xiaomi}/>
            case 'motorola':
              return <RenderGuide images={images.motorola} instructions={instructions.motorola}/>
            case 'lg':
              return <RenderGuide images={images.samsung} instructions={instructions.lg}/>
          }
        })()}
      </div>
    </div>

  );
}

export default InstallationGuide;
