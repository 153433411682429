import React from "react";
import {DotPulse} from "@uiball/loaders";

function LoadingFullscreen() {

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: "absolute",
        top: 0,
        left: 0
      }}>
        <DotPulse
          size={40}
          speed={1.3}
          color="black"
        />
      </div>
    </div>
  );
}

export default LoadingFullscreen;
