import React from 'react';

function MultipartNavigation({step, setStep, labels}) {
  const indexes = Array.from({length: labels.length}, (_, index) => index + 1);

  return (
    <div className="form-index">
      <div className="form-index-labels">
        {labels.map((label) => (
          <p key={label}>{label}</p>
        ))}
      </div>
      <div className="vertical-line"></div>
      <div className="form-index-buttons">
        {indexes.map((stepNumber) => (
          <React.Fragment key={stepNumber}>
            <div className="line"></div>
            <button
              className={step === stepNumber ? 'active-btn' : ''}
              onClick={() => setStep(stepNumber)}
            >
              {stepNumber}
            </button>
          </React.Fragment>
        ))}
        <div className="line"></div>
      </div>
    </div>
  );
}

export default MultipartNavigation;
