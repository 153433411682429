const routes = {
  ROOT: '/',
  LOGIN: '/',
  LOGOUT: '/login/logout',
  CHANGE_PASSWORD: '/r/senha/:username/:validationCode',
  REQUEST_CHANGE_PASSWORD: '/login/new-password',
  MAIN: '/main',
  ERROR: '/error-401',
  LOCK: '/lock',
  CUSTOMIZATION: '/customization',
  IMEI: '/imei',
  BULK_ACTIONS: '/bulk-actions',
  KNOX_DEVICES: '/knox-devices',
  DASHBOARD: '/billing',
  REPORTS: '/reports',
  HOME: '/home',
  UNAVAILABLE: '/unavailable',
  HARDWARE_LOCK: '/hardware-lock',
  NEW_PARTNER: '/new-partner',
  PARTNERS: '/partners',
  APP: '/app',
  APP_PARTNER: '/app/:code',
  DOCUMENTATION: 'https://www.globetek.com/docs',
  NOTIFICATION: '/notification'
};

export default routes;


