const DataHelper = {
  apkUrl: (data) => {
    return data
      && data.apkUrl
  },
  appIcon: (data) => {
    return data
      && data.appIcon
  },
  appLogo: (data) => {
    return data
      && data.appLogo
  },
  color: (data) => {
    return data
      && data.backgroundColor
  },
  logo: (data) => {
    return data
      && data.logo
  },
  name: (data) => {
    return data
      && data.name
  }
}

export default DataHelper;
