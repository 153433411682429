const instructions = {
  samsung: [
    'Download from this website.',
    'Find our app in the files folder.',
    'Make sure you can install from web.',
    'Configure our app, you need to activate accessibility.',
    'Activate admin and finish configuration.'
  ],
  motorola: [
    'Download from this website.',
    'Find our app in the files folder.',
    'Make sure you can install from web.',
    'Configure our app, you need to activate accessibility.'
  ],
  xiaomi: [
    'Get the app from our official website.',
    'Allow installation from the web in your device settings.',
    'Xiaomi will scan the app during installation, which is normal.',
    'Enable accessibility settings for full app functionality.',
    'Xiaomi may display security pop-ups during installation, which is normal.'
  ],
  lg: [
    'Download from this website.',
    'Find our app in the files folder.',
    'Make sure you can install from web.',
    'Configure our app, you need to activate accessibility.'
  ]
}
export default instructions;
