import React, { useState } from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import AppAdvertisingForm from "../../partner/app-advertising-form";
import { getI18n } from "react-i18next";

function AppAdvertising({ step, setStep, updateFormData, formData }) {
  const I18n = getI18n()

  function handleGoogleAdsChange(googleAds) {
    googleAds.enabled = adsIsEnabled(googleAds)
    formData.ads.googleAds = googleAds
    updateFormData("ads", formData.ads)
  }

  function handleStartIoAdsChange(startIoAds) {
    startIoAds.enabled = adsIsEnabled(startIoAds)
    formData.ads.startIoAds = startIoAds
    updateFormData("ads", formData.ads)
  }

  function adsIsEnabled(ads) {
    if (ads.enabled) return (ads.adUnitId.length > 0);
    else return false;
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual" style={{ display: "flex", gap: "90px" }}>
        <div style={{ width: '450px' }}>

          {/* GOOGLE ADS */}
          <AppAdvertisingForm
            title={I18n.t('custom.google_ads_title')}
            placeholder='ca-app-pub-0000000000000000/0000000000'
            adsId={formData.ads?.googleAds?.adUnitId || ''}
            adsEnabled={formData.ads?.googleAds?.enabled || false}
            handle={handleGoogleAdsChange}
          />

          {/* START IO ADS */}
          <AppAdvertisingForm
            title={I18n.t('custom.start_io_ads_title')}
            placeholder='000000000'
            adsId={formData.ads?.startIoAds?.adUnitId || ''}
            adsEnabled={formData.ads?.startIoAds?.enabled || false}
            handle={handleStartIoAdsChange}
          />

        </div>
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />
        <NextButton step={step} setStep={setStep} />
      </section>
    </div>
  );
}

export default AppAdvertising;
