import React from 'react';
import 'react-dropdown/style.css';
import {getI18n, withTranslation} from "react-i18next";
import LoadingComponent from "../../ui/loading/loading-component";

function Pagination({
                      totalNumber,
                      itemsPerPage,
                      currentPage,
                      setCurrentPage,
                      loading,
                      setLoading
                    }) {
  let I18n = getI18n();
  const pages = Math.ceil(totalNumber / itemsPerPage);

  function first(event) {
    event.preventDefault()
    setCurrentPage(Number(event.target.value))
    setLoading("first")
  }

  function previous(event) {
    event.preventDefault()
    setCurrentPage(currentPage - 1)
    setLoading("previous")
  }

  function next(event) {
    event.preventDefault()
    setCurrentPage(currentPage + 1)
    setLoading("next")
  }

  function last(event) {
    event.preventDefault()
    setCurrentPage(Number(event.target.value))
    setLoading("last")
  }

  return (
    <div className="pagination-container">
      <div className="pagination-bar">
        <button value={0} onClick={(event) => {
          first(event)
        }} disabled={currentPage === 0}>
          {loading === "first" ? <LoadingComponent pink={true}/> : null}
          {I18n.t('account_info.first')}
        </button>

        <button value={currentPage} onClick={(event) => {
          previous(event)
        }} disabled={currentPage === 0}>
          {loading === "previous" ? <LoadingComponent pink={true}/> : false}
          {I18n.t('account_info.previous')}
        </button>

        <button className="currentPage" value={currentPage}>{currentPage + 1}</button>

        <button value={currentPage} onClick={(event) => {
          next(event)
        }} disabled={currentPage === pages - 1}>
          {loading === "next" ? <LoadingComponent pink={true}/> : false}
          {I18n.t('account_info.next')}
        </button>

        <button value={pages - 1} onClick={(event) => {
          last(event)
        }} disabled={currentPage === pages - 1}>
          {loading === "last" ? <LoadingComponent pink={true}/> : false}
          {I18n.t('account_info.last')}
        </button>
      </div>
      <div className="pagination-numbers">
        {I18n.t('account_info.total_devices')} {totalNumber}
      </div>
    </div>
  );
}

const PaginationTranslated = withTranslation()(Pagination);
export default PaginationTranslated;
