import React from 'react';
import Accordion from 'components/accordion/accordion.js';
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import LoadingComponent from 'components/ui/loading/loading-component';
import { withTranslation } from 'react-i18next';
import * as Authentication from '../../utils/helpers/authentication-helper';
import IMEIFormTranslated from '../../components/ui/form/imei-page/imei-form';

class PartnerIMEI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      isDev: process.env.REACT_APP_ENV === 'dev',
      loadingSubmit: false,
      isAdmin: Authentication.isAdmin()
    };
  }

  saveSettings = () => {
    this.setState({ loadingSubmit: true });
    this.setState({ loadingSubmit: false });
  };


  render() {
    const { loading } = this.state;
    const { t } = this.props;
    let pageData = <LoadingComponent />;
    if (!loading) {
      pageData = (
        <div className="demo-container">
          <p className="page-title">{'IMEI'}</p>
          <div className="step-container" />
          <div className="accordion-list">

            {/* IMEI */}
            <Accordion
              active={true}
              expanded={true}
              title={t('custom.registration')}
              content={
                <IMEIFormTranslated />
              }
            />
          </div>
        </div>
      );
    }
    return pageData;
  }
}

const PartnerIMEITranslated = withTranslation()(PartnerIMEI);

export default PartnerIMEITranslated;
