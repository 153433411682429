import React from 'react';
import Accordion from 'components/accordion/accordion.js';
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import LoadingComponent from 'components/ui/loading/loading-component';
import { withTranslation } from 'react-i18next';
import PartnerConfigService from '../../service/partner-config-service';
import ToastHelper from '../../utils/helpers/toast-helper';
import RegistrationFormTranslated from '../../components/ui/form/settings-page/registration-form';
import BillingFormTranslated from '../../components/ui/form/settings-page/billing-form';
import routes from '../../navigation/routes';
import AppBackgroundColorForm from '../../components/ui/form/partner/app-background-color-form';
import AppLogoForm from '../../components/ui/form/partner/app-logo-form';
import AppLockMessageForm from '../../components/ui/form/partner/app-lock-message-form';
import AppWebhookForm from '../../components/ui/form/partner/app-webhook-form';
import AppLockTypeForm from '../../components/ui/form/partner/app-lock-type-form';
import AppConfigurationFieldForm from '../../components/ui/form/partner/app-configuration-field-form';
import ProductConfigHelper from '../../utils/helpers/product-config-helper';
import CredentialsForm from '../../components/ui/form/partner/credentials-form';
import ChangePasswordForm from '../../components/ui/form/partner/change-password-form';
import AppsManagementForm from '../../components/ui/form/partner/apps-management-form';
import * as Authentication from '../../utils/helpers/authentication-helper';
import AppWebhookKGFormTranslated from '../../components/ui/form/partner/app-webhook-kg-form';

class PartnerCustomization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      isDev: process.env.REACT_APP_ENV === 'dev',
      loading: true,
      logo: '',
      currentLogo: '',
      partnerPhoneNumber: '',
      allowedPhoneNumbers: '',
      allowedApplications: '',
      paymentPage: '',
      helpPage: '',
      emergencyNumbers: '',
      backgroundColor: '',
      lockMessage: '',
      lockType: '',
      webhook: '',
      webhookUrlCustomer: '',
      appConfiguration: null,
      loadingSubmit: false,
      updateLogo: false,
      updateBackground: false,
      updatePhone: false,
      updateLockMessage: false,
      updateWebhook: false,
      updateWebhookKG: false,
      updateAppConfiguration: false,
      updateLockType: false,
      isAdmin: Authentication.isAdmin()
    };
  }

  componentDidMount() {
    this.getPartnerConfig();
  }

  getPartnerConfig = async () => {
    try {
      const response = await PartnerConfigService.findPartnerConfig();
      const partnerConfiguration = response.data;
      const allowedPhoneNumbers =
        partnerConfiguration?.lockOptions?.allowedPhoneNumbers || [];
      const allowedApplications =
        partnerConfiguration?.lockOptions?.allowedApplications || [];
      const emergencyNumbers =
        partnerConfiguration?.lockOptions?.emergencyNumbers || [];

      // Populate state with partner configuration.
      this.setState({
        logo: partnerConfiguration?.customInterface?.logo || '',
        currentLogo: partnerConfiguration?.customInterface?.logo || '',
        partnerPhoneNumber:
          partnerConfiguration?.customInterface?.phoneNumber || '',
        allowedPhoneNumbers: allowedPhoneNumbers.join(','),
        allowedApplications: allowedApplications.join(','),
        emergencyNumbers: emergencyNumbers.join(','),
        paymentPage: partnerConfiguration?.customInterface?.paymentPage || '',
        helpPage: partnerConfiguration?.customInterface?.helpPage || '',
        backgroundColor: partnerConfiguration?.customInterface?.backgroundColor || '',
        lockMessage: partnerConfiguration?.lockOptions?.lockMessage || '',
        lockType: partnerConfiguration?.lockOptions?.lockType || '',
        webhook: partnerConfiguration?.customInterface?.webhook || '',
        webhookUrlCustomer: partnerConfiguration?.customInterface?.webhookUrlCustomer || '',
        appConfiguration: partnerConfiguration?.customInterface?.appConfiguration || null,
        lockOptions: partnerConfiguration?.lockOptions || null
      });
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }

    this.setState({ loading: false });
  };

  showPreview = (event) => {
    if (event.target.files.length > 0) {
      const src = URL.createObjectURL(event.target.files[0]);
      const preview = document.getElementById('file-ip-1-preview');
      preview.src = src;
      preview.style.display = 'block';

      this.setState({ image: true });
    }
  };

  hidePreview = () => {
    var preview = document.getElementById('file-ip-1-preview');
    preview.src = '';
    preview.style.display = 'none';

    this.setState({ ...this.state, image: false });
  };

  handleLogoChange = (file) => {
    this.setState({
      logo: file,
      updateLogo: true
    });
  };

  handleBackgroundColorChange = (color) => {
    this.setState({
      backgroundColor: color,
      updateBackground: true
    });
  };

  handlePartnerPhoneNumberChange = (event) => {
    const value = event.target.value;

    this.setState({
      partnerPhoneNumber: value.replace(/[^0-9]/g, ''),
      updatePhone: true
    });
  };

  handleLockMessageChange = (message) => {
    this.setState({
      lockMessage: message,
      updateLockMessage: true
    });
  };

  handleLockTypeChange = (lockType) => {
    this.setState({
      lockType: lockType,
      updateLockType: true
    });
  };

  handleWebhookChange = (webhook) => {
    this.setState({
      webhook: webhook,
      updateWebhook: true
    });
  };

  handleWebhookKGChange = (webhookUrlCustomer) => {
    this.setState({
      webhookUrlCustomer: webhookUrlCustomer,
      updateWebhookKG: true
    });
  };

  handleAppConfigurationChange = (config) => {
    this.setState({
      appConfiguration: config,
      updateAppConfiguration: true
    });
  };

  saveSettings = () => {
    this.setState({ loadingSubmit: true });

    try {
      this.state.updateBackground ? this.saveBackgroundColor() : console.log('no changes on background color');
      this.state.updateLogo ? this.saveLogo() : console.log('no changes on logo');
      this.state.updatePhone ? this.savePartnerPhoneNumber() : console.log('no changes on phone number');
      this.state.updateLockMessage ? this.saveLockMessage() : console.log('no changes on lock message');
      this.state.updateWebhook ? this.saveWebhook() : console.log('no changes on webhook');
      this.state.updateWebhookKG ? this.saveWebhookKG() : console.log('no changes on webhook kg');
      this.state.updateAppConfiguration ? this.saveAppConfiguration() : console.log('no changes on app configuration');
      this.state.updateLockType ? this.saveLockType() : console.log('no changes on lock type');
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
    this.setState({ loadingSubmit: false });
  };

  saveLogo = async () => {

    const { t } = this.props;

    if (!this.state.logo) {
      ToastHelper.showWarning(t('toast_helper.select_app_logo'));
      return;
    }

    this.setState({ littleLoading: 'save-image-url' });

    try {
      const logoData = new FormData();
      logoData.append('logo', this.state.logo);
      const response = await ToastHelper.showLoading(
        PartnerConfigService.updateLogo(logoData),
        {
          error: {
            message: t('toast_helper.update_logo_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_logo'),
          pending: t('toast_helper.updating_logo'),
        }
      );
      const savedLogo = response.data?.customInterface?.logo || '';
      this.setState({ logo: savedLogo, currentLogo: savedLogo });
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }

    this.setState({ littleLoading: '' });
  };

  savePartnerPhoneNumber = async () => {

    const { t } = this.props;

    if (!this.state.partnerPhoneNumber) {
      ToastHelper.showWarning(t('toast_helper.fill_phone_number'));
      return;
    }

    this.setState({ littleLoading: 'save-partner-phone-number' });

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updatePhoneNumber(this.state.partnerPhoneNumber),
        {
          error: {
            message: t('toast_helper.update_phone_number_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_phone_number'),
          pending: t('toast_helper.updating_phone_number'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }

    this.setState({ littleLoading: '' });
  };

  saveBackgroundColor = async () => {

    const { t } = this.props;

    if (!this.state.backgroundColor) {
      ToastHelper.showWarning(t('toast_helper.fill_background_color'));
      return;
    }

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateBackgroundColor(this.state.backgroundColor),
        {
          error: {
            message: t('toast_helper.update_background_color_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_background_color'),
          pending: t('toast_helper.updating_background_color'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  saveLockMessage = async () => {

    const { t } = this.props;

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateLockMessage(this.state.lockMessage),
        {
          error: {
            message: t('toast_helper.update_lock_message_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_lock_message'),
          pending: t('toast_helper.updating_lock_message'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  saveLockType = async () => {

    const { t } = this.props;

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateLockType(this.state.lockType),
        {
          error: {
            message: t('toast_helper.update_lock_type_fail_message'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_lock_type'),
          pending: t('toast_helper.updating_lock_type'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  saveWebhook = async () => {

    const { t } = this.props;

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateWebhook(this.state.webhook),
        {
          error: {
            message: t('toast_helper.update_webhook_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_webhook'),
          pending: t('toast_helper.updating_webhook'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  saveWebhookKG = async () => {

    const { t } = this.props;

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateWebhookKG(this.state.webhookUrlCustomer),
        {
          error: {
            message: t('toast_helper.update_webhook_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_webhook'),
          pending: t('toast_helper.updating_webhook'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  saveAppConfiguration = async () => {

    const { t } = this.props;

    let appConfiguration = {}
    if (ProductConfigHelper.appConfingurationFieldIsValid(this.state.appConfiguration)) {
      appConfiguration = this.state.appConfiguration
    }

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updateAppConfiguration(appConfiguration),
        {
          error: {
            message: t('toast_helper.update_app_configuration_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_app_configuration'),
          pending: t('toast_helper.updating_app_configuration'),
        }
      );
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  render() {
    const { loading } = this.state;
    const { t } = this.props;
    let pageData = <LoadingComponent />;
    if (!loading) {
      pageData = (
        <div className="demo-container">
          <p className="page-title">{t('aside.settings')}</p>
          <div className="step-container" />
          <div className="accordion-list">
            {/* App */}
            <Accordion
              active={true}
              title={t('custom.app')}
              content={
                <>
                  <section className="settings-forms-container">

                    {/* LOGO */}
                    <AppLogoForm
                      value={this.state.currentLogo}
                      handle={this.handleLogoChange} />

                    {/* Lock Type */}
                    <AppLockTypeForm
                      value={this.state.lockType}
                      handle={this.handleLockTypeChange} />

                    {/* LOCK MESSAGE */}
                    <AppLockMessageForm
                      value={this.state.lockMessage}
                      handle={this.handleLockMessageChange} />

                    {/* CONFIGURATION FIELD */}
                    <AppConfigurationFieldForm
                      value={this.state.appConfiguration}
                      handle={this.handleAppConfigurationChange} />

                    {/* WEBHOOK */}
                    <AppWebhookForm
                      value={this.state.webhook}
                      handle={this.handleWebhookChange} />

                    {/* WEBHOOK KG */}
                    <AppWebhookKGFormTranslated
                      value={this.state.webhookUrlCustomer}
                      handle={this.handleWebhookKGChange} />

                    {/* COLOR */}
                    <AppBackgroundColorForm
                      value={this.state.backgroundColor}
                      handle={this.handleBackgroundColorChange} />

                    {/* SERVICE NUMBER */}
                    <div className="input-container">
                      <p>{t('custom.service_text')}</p>
                      <input
                        type="text"
                        className="form-input"
                        value={this.state.partnerPhoneNumber}
                        onChange={this.handlePartnerPhoneNumberChange}
                      />
                      {/* TODO: Save all and include this.savePartnerPhoneNumber */}
                    </div>
                  </section>
                </>
              }
            />

            {/* Billing */}
            {this.state.isAdmin && (
              <Accordion
                active={true}
                title={t('custom.billing')}
                content={
                  <BillingFormTranslated />
                }
              />
            )}

            {/* Registration */}
            {this.state.isDev && (
              <Accordion
                active={true}
                title={t('custom.registration')}
                content={
                  <RegistrationFormTranslated />
                }
              />
            )}

            {/* Users */}
            {this.state.isDev && (
              <Accordion
                active={true}
                title={t('custom.users')}
                content={
                  <div>
                    <p>{t('custom.users_description')}</p>
                    <div className="center-all">
                      <button
                        type="submit"
                        className="short-button"
                        onClick={() => {
                          window.open(routes.UNAVAILABLE)
                        }}
                      >
                        {t('custom.manage_users')}
                      </button>
                    </div>
                  </div>
                }
              />
            )}

            {/* SECURITY */}
            <Accordion
              active={true}
              title={t('custom.security')}
              content={
                <div>
                  <CredentialsForm />

                  <ChangePasswordForm />
                </div>
              }
            />

            {/* APPS MANAGEMENT */}
            <Accordion
              active={true}
              title={t('custom.apps_management')}
              content={
                <div>
                  <AppsManagementForm
                    value={this.state.lockOptions} />
                </div>
              }
            />

            <div className="bottom-container">
              <button
                type="submit"
                className="short-button"
                onClick={this.saveSettings}
              >
                {t('custom.save')}
                {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return pageData;
  }
}

const PartnerCustomizationTranslated = withTranslation()(PartnerCustomization);

export default PartnerCustomizationTranslated;
