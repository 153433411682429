import API from 'config/api';

const NotificationService = {
  send: (notification) => {
    return API.post(`/notification/send`, notification);
  },
};

export default NotificationService;

