const ImageHelper = {
  validateImageSize: (file, width, height, callback) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function () {
      URL.revokeObjectURL(objectUrl);
      callback(this.width === width && this.height === height);
    };
    img.src = objectUrl;
  },
  verifyFormat: (image) => {
    if(typeof image === 'string'){
      return image
    } else if (image !== null) {
      return URL.createObjectURL(image)
    } else {
      return null
    }
  }
}

export default ImageHelper;
