import React from 'react';
import { withTranslation } from "react-i18next";
import ImageHelper from "../../../utils/helpers/image-helper";
import ToastHelper from '../../../../utils/helpers/toast-helper';
import { Tooltip } from '@material-ui/core';

class AppLogoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: props.logo,
      currentLogo: props.value,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col">
          <label>{t('custom.logo')}</label>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.image_upload')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>
        <label className="label-tooltip">800 x 126 {t('custom.image_recommended')}</label>

        <div onClick={() => { this.uploadLogo.click() }}>
          {(this.state.logo || this.state.currentLogo) ? (
            <img className="dimension-logo-example" src={this.getLogoUrl()} alt="logo" />
          ) : (
            <div className="dimension-logo-example">
              <p className="image-icon-plus">+</p>
            </div>
          )}
        </div>

        <input
          type='file'
          accept="image/png"
          ref={(ref) => this.uploadLogo = ref}
          onChange={this.handleLogoChange}
          style={{ display: 'none' }} />

        <br />
        <br />

      </div>
    );
  }

  getLogoUrl() {
    if(this.state.logo) return ImageHelper.verifyFormat(this.state.logo)
    else return this.state.currentLogo
  }

  handleLogoChange = (event) => {
    event.preventDefault()
    const { t } = this.props;

    var file
    if ((file = event.target.files[0])) {
        this.setState({ logo: file });
        this.props.handle(file)
    }
  };
}

const AppLogoFormTranslated = withTranslation()(AppLogoForm)

export default AppLogoFormTranslated
