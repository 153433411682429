import { applyMiddleware, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import logger from 'redux-logger';
import reducers from 'store/reducer/reducers';
import apiMiddlewareInterceptor from 'config/api-middleware-interceptor';
import StorageHelper from 'utils/helpers/storage-helper';

let middlewareToApply = null;
if (process.env.NODE_ENV !== 'development') {
  middlewareToApply = applyMiddleware(apiMiddlewareInterceptor, apiMiddleware);
} else {
  middlewareToApply = applyMiddleware(
    apiMiddlewareInterceptor,
    apiMiddleware,
    logger
  );
}

export default createStore(
  reducers,
  StorageHelper.loadStateFromLocalStorage(),
  applyMiddleware(apiMiddlewareInterceptor, apiMiddleware, logger)
);
