import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';

class AppConfigurationFieldForm extends React.Component {
  constructor(props) {
    super(props);

    let configuration = {
      label: props.value?.label || "",
      mask: props.value?.mask || "",
      placeHolder: props.value?.placeHolder || "",
      type: props.value?.type || "",
      doubleCheck: props.value?.doubleCheck || false
    }

    this.state = {
      appConfiguration: configuration,
      appConfigurationAux: configuration,
      showAppConfiguration: (configuration.label.length > 0)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col" onClick={this.handleShowAppConfigurationChange}>
          <label>{t('custom.app_configuration_label')}</label>
          <div >
            {this.state.showAppConfiguration ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        {this.state.showAppConfiguration ? (

          <div style={{ marginTop: "30px", marginBottom: "30px" }} >

            <div className="two-col" onClick={this.handleDoubleCheckChange}>
              <label>{t('custom.app_configuration_double_check')}</label>
              <div >
                {this.state.appConfiguration.doubleCheck ? (
                  <i className="bi bi-toggle-on" />
                ) :
                  <i className="bi bi-toggle-off" />
                }
              </div>
            </div>

            <div className="two-col">
              <div style={{ marginTop: "30px", width: "50%" }}>

                <label>{t('custom.app_configuration_field_name_label')}</label>
                <input
                  type="text"
                  className="form-input"
                  value={this.state.appConfiguration.label}
                  placeholder={t('custom.app_configuration_field_name_place_holder')}
                  onChange={this.handleNameChange} />
              </div>

              <div style={{ marginTop: "30px", width: "50%" }}>

                <label>{t('custom.app_configuration_field_type_label')}</label>
                <Dropdown
                  className="form-select-without-margin-horizontal"
                  options={ProductConfigHelper.appConfingurationFieldTypes()}
                  onChange={this.handleTypeChange}
                  value={this.state.appConfiguration.type ? this.state.appConfiguration.type : null}
                  placeholder={t('custom.select')} />
              </div>
            </div>

            <div className="two-col">

              <div style={{ width: "50%" }}>

                <label>{t('custom.app_configuration_field_mak_label')}</label>
                <input
                  type="text"
                  className="form-input"
                  value={this.state.appConfiguration.mask}
                  placeholder={t('custom.app_configuration_field_mak_place_holder')}
                  onChange={this.handleMaskChange} />
              </div>

              <div style={{ width: "50%" }}>
                <label>{t('custom.app_configuration_field_hint_label')}</label>

                <input
                  type="text"
                  className="form-input"
                  value={this.state.appConfiguration.placeHolder}
                  placeholder={t('custom.app_configuration_field_hint_place_holder')}
                  onChange={this.handlePlaceHolderChange} />
              </div>

            </div>

          </div>

        ) :
          <div style={{ marginTop: "30px", marginBottom: "30px" }} />
        }
      </div>
    );
  }

  handleNameChange = (event) => {
    let config = this.state.appConfiguration
    config.label = event.target.value
    this.updateAppConfiguration(config)
  };

  handleMaskChange = (event) => {
    let config = this.state.appConfiguration
    config.mask = event.target.value
    this.updateAppConfiguration(config)
  };

  handlePlaceHolderChange = (event) => {
    let config = this.state.appConfiguration
    config.placeHolder = event.target.value
    this.updateAppConfiguration(config)
  };

  handleTypeChange = (event) => {
    let config = this.state.appConfiguration
    config.type = event.value
    this.updateAppConfiguration(config)
  };

  updateAppConfiguration = (config) => {
    this.setState({
      appConfiguration: config,
      appConfigurationAux: config,
    });
    this.props.handle(config)
  };

  handleShowAppConfigurationChange = (event) => {

    let isChecked = !this.state.showAppConfiguration

    this.setState({ showAppConfiguration: isChecked });

    if (isChecked) {
      this.setState({ appConfiguration: this.state.appConfigurationAux });
      this.props.handle(this.state.appConfigurationAux)
    } else {
      this.setState({ appConfiguration: {} });
      this.props.handle({})
    }
  }

  handleDoubleCheckChange = (event) => {

    let isChecked = !this.state.doubleCheck
    this.setState({ doubleCheck: isChecked });

    let config = this.state.appConfiguration
    config.doubleCheck = isChecked

    this.updateAppConfiguration(config)
  };
}

const AppConfigurationFieldFormTranslated = withTranslation()(AppConfigurationFieldForm)

export default AppConfigurationFieldFormTranslated
