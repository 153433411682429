import * as applicationActions from 'store/action/application-actions';

let initialState = {
  loggedIn: false,
  token: null,
  isSeller: false,
  link: null,
  taxId: null,
  errors: null,
  messages: [],
  loading: [],
  contract: null,

  device: {
    kind: 1,
    code: null,
  },

  loan: {
    application: {
      sellerId: null,
      logs: {},
      payment: {
        dueDay: null,
        method: null,
      },
      personal: {
        id: null,
        sex: null,
        name: '',
        taxId: '',
        idIssuer: '',
        idNumber: null,
        birthDate: null,
        birthState: '',
        motherName: '',
        civilStatus: null,
        idIssueDate: null,
        nationality: 55,
        emailAddress: '',
        idIssueLocality: null,
        mobilePhoneNumber: null,
        mobilePhoneBrand: null,
      },
      financial: {
        property: null,
        principal: null,
        bankAccount: {
          kind: null,
          number: null,
          bankCode: null,
          checkDigit: '',
          branchNumber: null,
        },
        informedDefault: null,
        politicallyExposedPerson: null,
      },
      updatedAt: null,
      employment: {
        kind: null,
        payDay: null,
        address: '',
        employer: '',
        position: '',
        phoneNumber: null,
        monthlyIncomeRange: null,
        monthlyIncome: null,
      },
      finishedAt: null,
      cancelledAt: null,
      residential: {
        city: '',
        state: '',
        zipCode: null,
        typeName: null,
        complement: '',
        phoneNumber: null,
        neighborhood: '',
        streetAddress: '',
        streetNumber: '',
      },
      pendingInformation: [],
      conditionSelectedAt: null,
      conditionsCalculatedAt: null,
    },
    state: {
      id: null,
      phase: {
        id: null,
      },
    },
  },
};

/**
 * Loan reducer.
 * @param {*} state Current state.
 * @param {*} action Action to be perfomed.
 */
export default function application(state = initialState, action) {
  let newState;

  switch (action.type) {
    // If it is a principal select success action.
    case applicationActions.PRINCIPAL_SELECT_SUCCESS:
      newState = {
        ...state,
        errors: null,
      };
      newState.loan.application.financial.principal = action.payload;
      return newState;

    // If it is a application submit success action.
    case applicationActions.SUBMIT_NEW_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a application submit failure action.
    case applicationActions.SUBMIT_NEW_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    // If it is a application submit success action.
    case applicationActions.SUBMIT_BASIC_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a application submit failure action.
    case applicationActions.SUBMIT_BASIC_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    // If it is a application submit success action.
    case applicationActions.SUBMIT_PRE_CONTRACT_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a application submit failure action.
    case applicationActions.SUBMIT_PRE_CONTRACT_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    // If it is a application submit success action.
    case applicationActions.SIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a application submit failure action.
    case applicationActions.SIGN_CONTRACT_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    // If it is a Instantor success action.
    case applicationActions.SUBMIT_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a Instantor failure action.
    case applicationActions.SUBMIT_BANK_STATEMENT_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    // If it is a application submit success action.
    case applicationActions.SUBMIT_POST_CONTRACT_SUCCESS:
      return {
        ...state,
        errors: null,
        loan: action.payload == null ? initialState.loan : action.payload,
      };

    // If it is a application submit failure action.
    case applicationActions.SUBMIT_POST_CONTRACT_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case applicationActions.CONTINUE_LOAN_APPLICATION_SUCCESS:
      newState = {
        ...state,
        loan: action.payload == null ? initialState.loan : action.payload,
      };
      return newState;

    case applicationActions.CONTINUE_LOAN_APPLICATION_FAILURE:
      newState = { ...state, errors: action.payload };
      return newState;

    case applicationActions.LOAN_CONTRACT_SUCCESS:
      newState = {
        ...state,
        contract: action.payload,
      };
      return newState;

    case applicationActions.LOAN_CONTRACT_FAILURE:
      newState = { ...state, errors: action.payload };
      return newState;

    default:
      return { ...state };
  }
}
