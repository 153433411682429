const CurrentStep = {
  CREATED: "Created",
  GENERATED_DEMO_PACKAGE: "Generated Demo Package",
  CREATED_EXTERNAL_USER: "Created External User",
  CREATED_OAUTH2_CLIENT: "Created OAuth2 Client",
  EMAIL_SENT_WITH_USER_DATA: "Email Sent with User Data",
  GENERATED_DEMO_APK: "Generated Demo APK",
  UPLOADED_DEMO_KNOX: "Uploaded Demo Knox",
  EMAIL_SENT_WITH_DEMO_APK: "Email Sent with Demo APK",
  WAITING_FOR_APPROVAL: "Waiting for Approval",
  APPROVED: "Approved",
  EMAIL_SENT_WITH_COMPLETE_USER_DATA: "Email Sent with Complete User Data",
  SIGNED_CONTRACT: "Signed Contract",
  GENERATED_FULL_PACKAGES: "Generated Full Packages",
  GENERATED_FULL_APKS: "Generated Full APKs",
  UPLOADED_FULL_APKS_KNOX: "Uploaded Full APKs Knox",
  COMPLETED: "Completed"
};

export default CurrentStep;

