import React from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import DeviceMockup from "../ui/DeviceMockup/device-mockup";
import IconPreview from "../ui/IconPreview/icon-preview";
import AppIconForm from "../../partner/app-icon-form";
import AppLogoForm from "../../partner/app-logo-form";
import AppBackgroundColorForm from "../../partner/app-background-color-form";

import Logo from '../../../../../assets/images/logo_horizontal.svg';
import Button from "../../../button/button";
import ImageHelper from "../../../../utils/helpers/image-helper";

function AppVisual({step, setStep, updateFormData, formData}) {

  function handleAppIconChange(file) {
    updateFormData("appIcon", file);
    updateFormData("updateAppIcon", true);
  }

  function handleBackgroundColorChange(color) {
    updateFormData("backgroundColor", color);
  }

  function handleLogoChange(file) {
    updateFormData("logo", file);
    updateFormData("updateLogo", true);
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual" style={{display: "flex", gap: "30px"}}>
        <div style={{width: '450px'}}>
          <section className="app-visual-input">
            <AppIconForm appIcon={formData.appIcon} handle={handleAppIconChange}/>
            <IconPreview appIcon={formData.appIcon}/>
          </section>
          <br/>
          <AppLogoForm logo={formData.logo} handle={handleLogoChange}/>
          <br/>
          {/* COLOR */}
          <AppBackgroundColorForm value={formData.backgroundColor} handle={handleBackgroundColorChange}/>
        </div>

        {/* <br/><br/>
        <section className="app-visual-preview">
          <DeviceMockup body={
            <div style={{height: '100%', display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
              <img src={ImageHelper.verifyFormat(formData.logo) || Logo} alt=""
                   style={{width: '100%', margin: '0 auto', maxWidth: '200px'}}/>
              <Button label="Start configuration" color={formData.backgroundColor}/>
            </div>
          }/>
        </section> */}
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep}/>
        <NextButton step={step} setStep={setStep}/>
      </section>
    </div>
  );
}

export default AppVisual;
