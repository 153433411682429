import { getI18n } from 'react-i18next';
import moment from 'moment';

const DateHelper = {
  dateToDisplay: (date) => {
    date = date && date.length > 0 ? (date + '--').split('-') : date;
    return date && date.length > 0
      ? date[2] + '/' + date[1] + '/' + date[0]
      : date;
  },

  displayToDate: (date) => {
    date = date && date.length > 0 ? (date + '//').split('/') : date;
    return date && date.length > 0
      ? date[2] + '-' + date[1] + '-' + date[0]
      : date;
  },

  /**
   * Format a date.
   * @param {string | Date} date - The date to be formatted.
   * @returns {string} - The formatted date.
   */
  getFormattedDate: (date) => {
    const { language } = getI18n();
    const dt = date && new Date(date);
    return dt && Intl.DateTimeFormat(language || undefined).format(dt);
  },

  /**
   * Format a date.
   * @param {string | Date} date - The date to be formatted.
   * @returns {string} - The formatted date.
   */
  getFormattedMonth: (date) => {
    const dt = date && new Date(date).getMonth()+1;
    return dt;
  },

  /**
   * Format a date time.
   * @param {string | Date} date - The date time to be formatted.
   * @returns {string} - The formatted date time.
   */
  getFormattedDateTime: (date) => {
    const { language } = getI18n();
    const dt = date && new Date(date);
    return dt && Intl.DateTimeFormat(language || undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(dt);
  },

  /**
   * Parse month number to words.
   * @param {number} - The month to be formatted.
   * @returns {string} - The month in words.
   */
  monthInWords: (monthNumber) => {
    switch (monthNumber) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "Invalid Month";
    }
  },

  /**
     * Format a date time.
     * @param {string | Date} date - The date time to be formatted.
     * @param {string | Format} format - The format.
     * @returns {string} - The formatted date time.
     */
    getFormattedDateWithFormat: (date, format) => {
        if(date) {
          const dt = new Date(date);
          return moment(date).format(format);
        } else {
          return null
        }
    }
};

export default DateHelper;
