import React from 'react';

function Attribute(props) {

  const style = {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    maxWidth: '80%'
  }

  const icon = {
    width: '30px',
    margin: '0 auto'
  }

  const box = {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    width: '50px',
    minWidth: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div style={style}>
      <div style={box}>
        <img style={icon} src={props.icon} alt="icon"/>
      </div>
      <p>{props.content}</p>
    </div>
  );
}

export default Attribute;
