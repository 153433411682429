import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import CollateralService from '../../service/collateral-service';
import PartnerService from '../../service/partner-config-service';
import Dropdown from 'react-dropdown';
import 'react-datepicker/dist/react-datepicker.css';
import 'jspdf-autotable';
import * as Authentication from '../../utils/helpers/authentication-helper';
import ProductConfigHelper from '../../utils/helpers/product-config-helper';


function PartnerSummaryTable(props) {
  const { t } = props;

  const [items, setItems] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const isAdmin = Authentication.isAdmin()
  const [productCode, setProductCode] = useState(!isAdmin ? ProductConfigHelper.getProductCode() : 'demo');

  useEffect(() => {
    const fetchData = async () => {
      if (isAdmin && productCode === '') return;

      const result = await CollateralService.countByStatus(productCode);

      const formattedItems = {
        'Active': '-',
        'Locked': '-',
        'Released': '-'
      };

      result.data.forEach(([key, value]) => {
        formattedItems[key] = value;
      });

      setItems(formattedItems);
    };

    fetchData();
  }, [isAdmin, productCode]);

  useEffect(() => {
    const loadPartners = async () => {
      try {
        const result = await PartnerService.findAllPartners(0, 500, 'DESC');
        if(result && result.data.content.length > 0) {
          const filteredProductCodes = result.data.content
            .map((item, index) => ({ value: index, label: item.productCode }));

          filteredProductCodes.sort((a, b) => a.label.localeCompare(b.label));
          setProductCodes(filteredProductCodes);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadPartners();
  }, []);

  const handlePartnerChange = (event) => {
    setProductCode(event.label);
  };

  /* Render Chart */
  return (
    <div className="group-components">
         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div>
              <p className="silver label-same-size" style={{ margin: 0, display: isAdmin ? 'visible' : 'none' }}>{t('billing.partner')}</p>
            </div>

           {isAdmin ? (
             <div className="input-container" style={{width: '220px'}}>
             <Dropdown
               className="form-select-without-margin-horizontal"
               options={productCodes}
               onChange={handlePartnerChange}
               value={productCode}
               placeholder={t('billing.select')}
             /> </div>) :
             ''
            }
         </div>
      <table className="custom-table" style={{ maxWidth: "100vw" }}>
        <thead className="table-header">
        <tr>
          <td>
            <div className="align-div">{t("billing.active")}</div>
          </td>
          <td>
            <div className="align-div">{t("billing.locked")}</div>
          </td>
          <td>
            <div className="align-div">{t("billing.released")}</div>
          </td>
        </tr>
        </thead>
        <tbody className="custom-tbody">
        <tr>
          <td>
            <div className="align-div">{items.Active}</div>
          </td>
          <td>
            <div className="align-div">{items.Locked}</div>
          </td>
          <td>
            <div className="align-div">{items.Released}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

const PartnerSummaryTableTranslated = withTranslation()(PartnerSummaryTable)
export default PartnerSummaryTableTranslated
