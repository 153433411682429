import React, {useState} from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import AppLockMessageForm from "../../partner/app-lock-message-form";
import {Tooltip} from "react-bootstrap";
import {getI18n} from "react-i18next";
import DeviceMockup from "../ui/DeviceMockup/device-mockup";
import Button from "../../../button/button";

import LockIcon from '../../../../../assets/images/lock_icon.svg';

function AppContent({ step, setStep, updateFormData, formData}) {
  const I18n = getI18n()

  function handlePhoneNumberChange(event) {
    updateFormData("phoneNumber", event.target.value);
  }

  function handleUrlChange(event) {
    let url = event.target.value;
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    updateFormData("helpPage", url);
  }

  function handleLockMessageChange(message) {
    updateFormData("lockMessage", message);
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual" style={{display: "flex", gap: "90px"}}>
        <div style={{width: '450px'}}>
          {/* SERVICE NUMBER */}
          <div className="input-container">
            <div className="two-col">
              <label>{I18n.t('custom.service_label')}</label>
              <Tooltip style={{backgroundColor: 'transparent'}} title={I18n.t('custom.service_text')} placement="top"
                       id="">
                <i className="bi bi-info-circle-fill"></i>
              </Tooltip>
            </div>
            <input
              value={formData.phoneNumber}
              type="text"
              className="form-input"
              placeholder={I18n.t('custom.service_place_holder')}
              onChange={(event) => handlePhoneNumberChange(event)}
            />
          </div>

          {/* HELP PAGE */}
          <div className="input-container">
            <div className="two-col">
              <label>{I18n.t('custom.help_url_label')}</label>
              <Tooltip style={{backgroundColor: 'transparent'}} title={I18n.t('custom.help_url_text')} placement="top"
                       id="">
                <i className="bi bi-info-circle-fill"></i>
              </Tooltip>
            </div>
            <input
              value={formData.helpPage}
              type="text"
              className="form-input"
              placeholder={I18n.t('custom.help_url_place_holder')}
              onChange={(event) => handleUrlChange(event)}
            />
          </div>

          {/* LOCK MESSAGE */}
          <AppLockMessageForm value={formData.lockMessage} handle={handleLockMessageChange}/>
        </div>
        {/* <section className="app-visual-preview">
          <DeviceMockup body={
            <div style={{
              textAlign: 'center',
              display: 'grid',
              justifyContent: 'center',
              padding: '10px',
              alignItems: 'center',
              height: '100%',
              alignContent: 'center',
              gap: '10px'
            }}>

              <div style={{display: 'grid', justifyContent: 'center', gap: '30px'}}>
                <img style={{margin: '0 auto'}} src={LockIcon} alt="lock"/>
                <p>{formData.lockMessage}</p>
              </div>

              {formData.phoneNumber && (
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '30px'}}>
                  <Button label="Call to us"/>
                </div>
              )}

              {formData.helpPage && (
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                  <a href={formData.helpPage} target={"_blank"}>
                    <Button label="Help page"/>
                  </a>
                </div>
              )}
            </div>
          }/>
        </section> */}
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep}/>
        <NextButton step={step} setStep={setStep}/>
      </section>
    </div>
  );
}

export default AppContent;
