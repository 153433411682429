import API from 'config/api';
import * as Authentication from 'utils/helpers/authentication-helper';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { inflate } from "pako"
import {decodeJwt} from "jose";
import routes from 'navigation/routes';



const REFRESH_TIMEOUT = 10000;
let listenerStarted = false;

const AuthenticationService = {
  waitingRefreshToken: () => {
    return localStorage.getItem('doRefreshToken') === '1';
  },
  isRefreshTokenRequest: (response) => {
    return response.config.url === '/api/authenticate/refresh_token';
  },
  startListenerRefreshToken: () => {
    if (listenerStarted) {
      return;
    }
    AuthenticationService.refreshToken();
    if (!listenerStarted) {
      listenerStarted = true;
    }
  },
  refreshToken: async () => {
    if (Authentication.isAuthenticated()) {
      try {
          const response = await API.put('/login/refresh_token');
              localStorage.setItem('doRefreshToken', '0');
            Cookies.set('oauth2Token', response.data.token); // Updates the token and cookie
            } catch (error) {
              localStorage.setItem('doRefreshToken', '1');
              AuthenticationService.handleLogout(); // Force logout in case of refresh error
            }
              setTimeout(AuthenticationService.refreshToken, REFRESH_TIMEOUT);
    }
  },

  getCookie: (cookieName) => {
    return Cookies.get(cookieName);
  },

  hasAuthority: (authority) => {
    const oauth2Token = AuthenticationService.getCookie('oauth2Token');
    if (oauth2Token) {
      const token =  AuthenticationService.decodeAndInflateToken(oauth2Token);
      const authorities = token ?.authorities || [];
        return authorities.includes(authority.toUpperCase());
    }
    return false;
  },

  hasAnyAuthorities: (authorities) => {
    return authorities.some((a) => AuthenticationService.hasAuthority(a));
  },

  hasAuthorities: (authorities) => {
  return authorities.every((a) => AuthenticationService.hasAuthority(a));
  },

  getUser: ()=>{
    const oauth2Token = AuthenticationService.getCookie('oauth2Token');
    if (oauth2Token) {
      return AuthenticationService.decodeAndInflateToken(oauth2Token);
    }
    return null;
  },
  decodeAndInflateToken: (oauth2Token) => {
    try{
    const compressedToken = decodeJwt(oauth2Token);
    if (compressedToken.authorities) {
      return compressedToken; // Not compressed, possible old auth version
    }
  const compressedBodyBin = new Uint8Array(JSON.parse(compressedToken.compressed));
        const inflatedBody = inflate(compressedBodyBin);
        compressedToken.authorities = String.fromCharCode(...inflatedBody);
        return compressedToken;
      }catch (error){
        AuthenticationService.handleLogout(); // In case of error decoding the token, force logout
        return null;
    }
  },

  authenticate: (username, password) => {
    return API.post(
      '/login/authenticate',
      { username, password },
      {
        headers: {
          'Content-Type': 'application/json',
          'SuperSim-Persistent-Log': JSON.stringify({
            device: { kind: 1, code: null },
          }),
        },
      }
    );
  },

  logout:() => {
    AuthenticationService.clearSession(); // Clear cache/cookies before exiting
    return API.delete('/login/logout', {
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: { kind: 1, code: null },}),
      },
    });


  },
  // changePassword(username, validationCode, password) {
  //   return API.post(
  //     `/login/change?username=${username}`,
  //     {
  //       validationCode,
  //       newPassword: password,
  //     },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'SuperSim-Persistent-Log': JSON.stringify({
  //           device: { kind: 1, code: null },
  //         }),
  //       },
  //     }
  //   );
  // },
  // changePassword(oldPassword, newPassword) {
  //   return API.post(
  //     `/login/change?username=${username}`,
  //     {
  //       validationCode,
  //       newPassword: password,
  //     },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'SuperSim-Persistent-Log': JSON.stringify({
  //           device: { kind: 1, code: null },
  //         }),
  //       },
  //     }
  //   );
  // },
  // requestChangePassword(username) {
  //   return API.get(`/login/change/request?username=${username}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'SuperSim-Persistent-Log': JSON.stringify({
  //         device: { kind: 1, code: null },
  //       }),
  //     },
  //   });
  // },


//Main cleanings that the clearSession function performs
    clearSession: () => {
      Cookies.remove('oauth2Token');
      Cookies.remove('oauth2RefreshToken');
      localStorage.removeItem('doRefreshToken');
    },
    handleLogout: () => {
      AuthenticationService.clearSession();
      navigate(routes.LOGOUT); // Path to redirect to login page
    },


  changePassword: (parameters) => API.post('/login/change-password', parameters),
  forgotPassword: (user) => API.post(`/login/forgot-password?user=${encodeURIComponent(user)}`),

};

export default AuthenticationService;
