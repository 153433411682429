/* Samsung */
import SM01 from './samsung/SM01.png';
import SM02 from './samsung/SM02.png';
import SM03 from './samsung/SM03.png';
import SM04 from './samsung/SM04.png';
import SM05 from './samsung/SM05.png';
import SM06 from './samsung/SM06.png';
import SM07 from './samsung/SM07.png';
import SM08 from './samsung/SM08.png';
import SM09 from './samsung/SM09.png';

/* Motorola */
import MT01 from './motorola/MT01.png';
import MT02 from './motorola/MT02.png';
import MT03 from './motorola/MT03.png';
import MT04 from './motorola/MT04.png';
import MT05 from './motorola/MT05.png';
import MT06 from './motorola/MT06.png';
import MT07 from './motorola/MT07.png';
import MT08 from './motorola/MT08.png';
import MT09 from './motorola/MT09.png';

/* Xiaomi */
import XO01 from './xiaomi/XO01.png';
import XO02 from './xiaomi/XO02.png';
import XO03 from './xiaomi/XO03.png';
import XO04 from './xiaomi/XO04.png';
import XO05 from './xiaomi/XO05.png';
import XO06 from './xiaomi/XO06.png';
import XO07 from './xiaomi/XO07.png';
import XO08 from './xiaomi/XO08.png';
import XO09 from './xiaomi/XO09.png';
import XO10 from './xiaomi/XO10.png';
import XO11 from './xiaomi/XO11.png';
import XO12 from './xiaomi/XO12.png';
import XO13 from './xiaomi/XO13.png';
import XO14 from './xiaomi/XO14.png';
import XO15 from './xiaomi/XO15.png';
import XO16 from './xiaomi/XO16.png';

const images = {
    samsung: [SM01, SM02, SM03, SM04, SM05, SM06, SM07, SM08, SM09],
    motorola: [MT01, MT02, MT03,MT04, MT05, MT06, MT07, MT08, MT09],
    xiaomi: [XO01, XO02, XO03, XO04, XO05, XO06, XO07, XO08, XO09, XO10, XO11, XO12, XO13, XO14, XO15, XO16]
}



export default images;
