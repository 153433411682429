import React from 'react';
import { withTranslation } from "react-i18next";

class AppAdvertisingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsId: props.adsId,
      adsEnabled: props.adsEnabled,
      title: props.title,
      placeholder: props.placeholder
    };
  }

  render() {
    return (
      <div className="input-container">
        <div className="two-col" onClick={this.handleAdsEnableChange}>
          <label>{this.state.title}</label>
          <div >
            {this.state.adsEnabled ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        <input
          readOnly={!this.state.adsEnabled}
          value={this.state.adsId}
          type="text"
          className="form-input"
          placeholder={this.state.placeholder}
          onChange={(event) => this.handleAdsIdChange(event)}
        />

      </div>
    );
  }

  handleAdsIdChange = (event) => {
    this.setState({
      adsId: event.target.value
    });
    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: event.target.value
    }
    this.props.handle(ads)
  };

  handleAdsEnableChange = (event) => {

    let isChecked = !this.state.adsEnabled

    this.setState({ adsEnabled: isChecked });

    let ads = {
      enabled: isChecked,
      adUnitId: this.state.adsId
    }

    this.props.handle(ads)

  }
}

const AppAdvertisingFormTranslated = withTranslation()(AppAdvertisingForm)

export default AppAdvertisingFormTranslated
