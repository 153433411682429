import React, {useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion/dist/framer-motion'

function HorizontalScroll(props) {
  const [width, setWidth] = useState(0)
  const carousel = useRef()

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
  }, []);

  return (
        <motion.div ref={carousel} className="carrousel" whileTap={{cursor: "grabbing"}}>
          <motion.div drag="x" dragConstraints={{right: 0, left: -width}} className="inner-carrousel">
            {props.content}
          </motion.div>
        </motion.div>

  );
}


export default HorizontalScroll;
