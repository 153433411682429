import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/styles/partner-portal.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18nextConf';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
<I18nextProvider i18n={i18next}>
    <App/>
</I18nextProvider>)
