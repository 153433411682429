import React, { useState } from 'react';
import "./style/_multipart.css";
import BasicInformation from "./steps/basic-information";
import AppVisual from "./steps/app-visual";
import AppContent from "./steps/app-content";
import AdvancedSettings from "./steps/advanced-settings";
import AppAdvertising from "./steps/app-advertising";
import AppVersion from "./steps/app-version";
import MultipartNavigation from "./ui/multipart-navigation";
import AppTemporaryUnlock from './steps/app-temporary_unlock';
import AppPush from './steps/app-push';

function MultipartForm(props) {
  const [step, setStep] = useState(1);

  return (
    <div className="multipart">
      <MultipartNavigation step={step} setStep={setStep}
        labels={['Basic information', 'App images and colors', 'App text', 'App advertising', 'App version', 'App Temporary Unlock', 'App Push', 'Advanced settings']}
      />

      <section>
        {step === 1 && (
          <BasicInformation
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}

        {step === 2 && (
          <AppVisual
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}

        {step === 3 && (
          <AppContent
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
        {step === 4 && (
          <AppAdvertising
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
        {step === 5 && (
          <AppVersion
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
        {step === 6 && (
          <AppTemporaryUnlock
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
        {step === 7 && (
          <AppPush
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
        {step === 8 && (
          <AdvancedSettings
            step={step}
            setStep={setStep}
            updateFormData={props.updateFormData}
            formData={props.formData}
          />
        )}
      </section>
    </div>
  );
}

export default MultipartForm;
