import React, {useState} from 'react';
import NumberLi from '../UI/number-li';

import Modal from '../Modal/modal';
import HorizontalScroll from "../UI/horizontal-scroll";

/* This guide template requires images and instructions array.
  visit: Manual -> data */
function RenderGuide(props) {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item);
  };

  const handelRotationRight = () => {
    const totalLength = props.images.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = props.images[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = props.images.filter((item) => {
      return props.images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = props.images.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = props.images[totalLength - 1];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = props.images.filter((item) => {
      return props.images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <div className="installation-container">
      <ul>

        {props.instructions.map((item, index) => (
          <NumberLi number={index + 1} content={item} key={index}/>
        ))}
      </ul>

      <div className="wrapper">
        <HorizontalScroll
          content={props.images.map((item, index) => (
            <div key={index} className="wrapper-images">
              <img
                src={item}
                alt={"installation print"}
                onClick={() => handleClick(item, index)}
              />
            </div>
          ))}
        />
        <div>
          {clickedImg && (
            <Modal
              clickedImg={clickedImg}
              handelRotationRight={handelRotationRight}
              setClickedImg={setClickedImg}
              handelRotationLeft={handelRotationLeft}
            />
          )}
        </div>
      </div>
    </div>
  );
}


export default RenderGuide;
