import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import ToastHelper from '../../../../utils/helpers/toast-helper';
import AuthenticationService from '../../../../service/authentication-service';

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isShowCurrentPassword: false,
      isShowNewPassword: false,
      isShowConfirmPassword: false,
      loadingSubmit: false
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">

        <div style={{ marginTop: "30px" }}>
          <p className="accordion-title">{t('change_password.title')}</p>
          <div className="two-col">
            <p>{t('change_password.current_password')}</p>
            <i
              className={this.state.isShowCurrentPassword ? "bi bi-eye-slash" : "bi bi-eye"}
              onClick={this.chanceStatusIsShowCurrentPassword}
            />
          </div>
          <input
            type={this.state.isShowCurrentPassword ? "text" : "password"}
            className="form-input"
            onChange={this.handleCurrentPasswordChange}
          />
          <div className="two-col">
            <p>{t('change_password.new_password')}</p>
            <i
              className={this.state.isShowNewPassword ? "bi bi-eye-slash" : "bi bi-eye"}
              onClick={this.chanceStatusIsShowNewPassword}
            />
          </div>

          <input
            type={this.state.isShowNewPassword ? "text" : "password"}
            className="form-input"
            onChange={this.handleNewPasswordChange}
          />
          <div className="two-col">
            <p>{t('change_password.confirm_password')}</p>
            <i
              className={this.state.isShowConfirmPassword ? "bi bi-eye-slash" : "bi bi-eye"}
              onClick={this.chanceStatusIsShowConfirmPassword}
            />
          </div>
          <input
            type={this.state.isShowConfirmPassword ? "text" : "password"}
            className="form-input"
            onChange={this.handleConfirmPasswordChange}
          />

          <p><label className="label-tooltip">{t('change_password.instructions_1')}</label></p>
          <p><label className="label-tooltip">{t('change_password.instructions_2')}</label></p>

          <div className="center-all">
            <button
              type="submit"
              className="short-button"
              onClick={this.savePassword}
            >
              {t('change_password.update')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>
          </div>
        </div>

      </div>
    );
  }

  chanceStatusIsShowCurrentPassword = (event) => {
    event.preventDefault()
    this.setState({
      isShowCurrentPassword: !this.state.isShowCurrentPassword
    });
  };

  chanceStatusIsShowNewPassword = (event) => {
    event.preventDefault()
    this.setState({
      isShowNewPassword: !this.state.isShowNewPassword
    });
  };

  chanceStatusIsShowConfirmPassword = (event) => {
    event.preventDefault()
    this.setState({
      isShowConfirmPassword: !this.state.isShowConfirmPassword
    });
  };

  handleCurrentPasswordChange = (event) => {
    this.setState({
      currentPassword: event.target.value
    });
  };

  handleNewPasswordChange = (event) => {
    this.setState({
      newPassword: event.target.value
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value
    });
  };

  savePassword = (event) => {

    const { t } = this.props;

    if (!this.state.currentPassword) {
      ToastHelper.showWarning(t('toast_helper.fill_current_password'));
      return
    }

    if (!this.state.newPassword) {
      ToastHelper.showWarning(t('toast_helper.fill_new_password'));
      return
    }

    if (!this.state.confirmPassword) {
      ToastHelper.showWarning(t('toast_helper.fill_confirm_password'));
      return
    }

    if (this.state.confirmPassword != this.state.newPassword) {
      ToastHelper.showWarning(t('toast_helper.passwords_not_match'));
      return
    }

    if (this.state.newPassword.length < 10) {
      ToastHelper.showWarning(t('toast_helper.valid_new_password'));
      return
    }

    const containsLetters = /[a-zA-Z]/.test(this.state.newPassword);
    const containsNumbers = /\d/.test(this.state.newPassword);

    if (!containsLetters || !containsNumbers) {
      ToastHelper.showWarning(t('toast_helper.valid_new_password'));
      return
    }

    this.changePassword(this.state.currentPassword, this.state.newPassword)
  }

  changePassword = async (oldPassword, newPassword) => {

    const { t } = this.props;

    this.setState({ loadingSubmit: true });

    try {

      let body = {
        'oldPassword': oldPassword,
        'newPassword': newPassword
      };

      await ToastHelper.showLoading(
        AuthenticationService.changePassword(body),
        {
          error: {
            message: t('toast_helper.update_password_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.updated_password'),
          pending: t('toast_helper.updating_password'),
        }
      );
    } catch (error) {
      console.error(error);
    }

    this.setState({ loadingSubmit: false });

  };
}

const ChangePasswordFormTranslated = withTranslation()(ChangePasswordForm)

export default ChangePasswordFormTranslated
