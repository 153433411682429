import React from 'react';
import {withTranslation} from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import LittleLoadingComponent from '../../loading/little-loading-component';
import ToastHelper from '../../../../utils/helpers/toast-helper';
import KnoxDevicesService from '../../../../service/knox_devices-service';
import KnoxDevicesHelper from 'utils/helpers/knox-devices-helper';
import { t } from 'i18next';
import history from 'navigation/history';

class IMEIForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false,
      user_id: '',
      imei: ''
    };
  }

  saveIMEI = async () => {

    if (!this.state.imei) {
      ToastHelper.showWarning(t('toast_helper.fill_imei'));
      return;
    }

    if(!KnoxDevicesHelper.validateIMEI(this.state.imei)) {
      ToastHelper.showWarning(t('toast_helper.invalid_imei'));
      return;
    }

    if (!this.state.user_id) {
      ToastHelper.showWarning(t('toast_helper.fill_user_id'));
      return;
    }

    this.setState({ loadingSubmit: true });

    const body = {
      imei : this.state.imei,
      user_id : this.state.user_id
    }

    ToastHelper.showLoading(
      KnoxDevicesService.create(body),
      {
        error: {
          message: t('toast_helper.new_imei_conflict'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(response => {

        this.setState({
          imei: '',
          user_id: ''
        });

        this.setState({
          loadingSubmit: false
        });

        if(response.status === 200) {
          history.goBack();
        }

      })
      .catch(e => {
        console.log(e)
        this.setState({ loadingSubmit: false });
      });
    // };
  }

  handleIMEIDevice = (event) => {
    this.setState({ imei: event.target.value });
  };

  handleUserID = (event) => {
    this.setState({ user_id: event.target.value });
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <section className="settings-forms-container">
          {/* IMEI Device */}
          <div className="input-container">
            <label className="label">{t('imei.imei_device')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.imei}
              onChange={this.handleIMEIDevice}
            />
          </div>

          {/* Company name */}
          <div className="input-container">
            <label className="label">{t('imei.user_id')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.user_id}
              onChange={this.handleUserID}
            />
          </div>

          <div className="center-all">
            <button
              type="submit"
              className="short-button"
              onClick={this.saveIMEI}>
              {t('custom.save')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>

          </div>
        </section>
      </>
    );
  }
}

const IMEIFormTranslated = withTranslation()(IMEIForm)

export default IMEIFormTranslated
