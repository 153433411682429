import React from 'react';
import {withTranslation} from 'react-i18next';
import LoadingComponent from 'components/ui/loading/loading-component';
import ChartCountyByStatusTranslated from "../../components/charts/ChartCountyByStatus";
import ChartVisualizeDataByDate from "../../components/charts/ChartVisualizeDataByDate";
import BillingTableTranslated from "../../components/tables/billing-table";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as Authentication from '../../utils/helpers/authentication-helper';
import PartnerSummaryTableTranslated from '../../components/tables/partner-summary-table';
import ChartVisualizeDataByDateTranslated from '../../components/charts/ChartVisualizeDataByDate';

class PartnerReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAdmin: Authentication.isAdmin()
    };
  }

  render() {
    const {loading} = this.state;
    const {t, i18n} = this.props;

    if (loading) return <LoadingComponent/>;
    else {
      return (
        <>
          <section className="dashboard">
            <p className="page-title">{t('aside.reports')}</p>
            <section className="charts">
              <PartnerSummaryTableTranslated />
              <ChartCountyByStatusTranslated />
              <ChartVisualizeDataByDateTranslated />
            </section>
          </section>
        </>
      );
    }
  }
}

const PartnerDashboardTranslated = withTranslation()(PartnerReports);

export default PartnerDashboardTranslated;
