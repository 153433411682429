import React, { useState } from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import AppTemporaryUnlockForm from '../../partner/app-temporary-unlock-form';
import { getI18n } from "react-i18next";

function AppTemporaryUnlock({ step, setStep, updateFormData, formData }) {
  const I18n = getI18n()

  function handleTemporaryUnlockChange(temporaryUnlock) {
    updateFormData("temporaryUnlock", temporaryUnlock);
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual">
        <div style={{ width: '450px' }}>

          {/* TEMPORY UNLOCK */}
          <AppTemporaryUnlockForm
            value={formData.temporaryUnlock}
            handle={handleTemporaryUnlockChange} />

        </div>
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />
        <NextButton step={step} setStep={setStep} />
      </section>
    </div>
  );
}

export default AppTemporaryUnlock;
