import React, { useState } from "react";

import { withTranslation } from "react-i18next";
import CopyInput from "../copy-input/copy-input";
import { getI18n } from 'react-i18next';

function RegenerateApiKeyDialogSuccess(props) {

  let isOpen = props.isOpen
  let confirmClickHandler = props.confirmClickHandler
  let clientId = props.clientId
  let clientSecret = props.clientSecret

  let I18n = getI18n();

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">

            <p>{I18n.t('toast_helper.generate_new_api_key_success')}</p>

            <section style={{ marginTop: "30px" }}>
              <p>{I18n.t('custom.client_id')}</p>
              <CopyInput text={clientId} />
              <p>{I18n.t('custom.client_secret')}</p>
              <CopyInput text={clientSecret} />
            </section>

            <div className="center-all">
              <button
                className="short-button"
                onClick={confirmClickHandler}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const RegenerateApiKeyDialogSuccessTranslated = withTranslation()(RegenerateApiKeyDialogSuccess)
export default RegenerateApiKeyDialogSuccessTranslated
