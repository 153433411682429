import React from 'react';
import Accordion from 'components/accordion/accordion.js';
import { withTranslation } from 'react-i18next';
import BillingFormTranslated from '../../components/ui/form/settings-page/billing-form';
import * as Authentication from '../../utils/helpers/authentication-helper';
import KnoxUploadFormTranslated from '../../components/ui/form/imei-page/bulk-upload-form';
import KnoxLockFormTranslated from '../../components/ui/form/imei-page/bulk-lock-form';
import KnoxUnlockFormTranslated from '../../components/ui/form/imei-page/bulk-unlock-form';
import KnoxReleaseFormTranslated from '../../components/ui/form/imei-page/bulk-release-form';
import KnoxSendMessageFormTranslated from '../../components/ui/form/imei-page/bulk-send-message-form';

class BulkActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      isDev: process.env.REACT_APP_ENV === 'dev',
      loading: false,
      isAdmin: Authentication.isAdmin()
    };
  }

  render() {
    const { t } = this.props;
      return (
        <div className="demo-container">
          <p className="page-title">{t('knox_devices.bulk_actions')}</p>
          <div className="step-container" />
          <div className="accordion-list">

            {/* Upload Device */}
            <Accordion
              active={true}
              title={t('bulk_actions.upload')}
              content={
                <KnoxUploadFormTranslated />
              }
            />

            {/* Lock */}
            <Accordion
              active={true}
              title={t('bulk_actions.lock')}
              content={
                <KnoxLockFormTranslated />
              }
            />

            {/* Unlock */}
            <Accordion
              active={true}
              title={t('bulk_actions.unlock')}
              content={
                <KnoxUnlockFormTranslated />
              }
            />

            {/* Release */}
            <Accordion
              active={true}
              title={t('bulk_actions.release')}
              content={
                <KnoxReleaseFormTranslated />
              }
            />

            {/* Send Message */}
            <Accordion
              active={true}
              title={t('bulk_actions.send_message')}
              content={
                <KnoxSendMessageFormTranslated />
              }
            />

          </div>
        </div>
      );
  }
}

const BulkActionsTranslated = withTranslation()(BulkActions);

export default BulkActionsTranslated;
