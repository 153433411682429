import React from 'react';
import {withTranslation} from "react-i18next";
import FadeIn from "react-fade-in";
class PageUnavailable extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <FadeIn>
        <section className="unavailable-container">
          <div className="unavailable">
            <i className="bi bi-x"></i>
            <p>{t('unavailable.unavailable')}</p>
          </div>
        </section>
      </FadeIn>
    );
  }
}

const PageUnavailableTranslated = withTranslation()(PageUnavailable);
export default PageUnavailableTranslated;
