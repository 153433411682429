import React from 'react';
import './style/icon-preview.css'
import ImageHelper from "../../../../../utils/helpers/image-helper";

function IconPreview(props) {

  return (
      <div className="icon-preview-container">
        <section className="icons-preview">
          <div className="icon-pattern instagram-background" style={{alignItems: 'initial'}}>
            <i className="bi bi-instagram"></i>
          </div>
          <div className="icon-pattern icon-placeholder" >
            {props.appIcon && (
              <img className="icon-pattern-image" style={{borderRadius: '10px'}} src={ImageHelper.verifyFormat(props.appIcon)}  alt=""/>
            ) }
          </div>
          <div className="icon-pattern twitch-background" style={{alignItems: 'initial'}}>
            <i className="bi bi-twitch"></i>
          </div>
        </section>
        <section className="navigation-controls">
          <i className="bi bi-list"></i>
          <i className="bi bi-app"></i>
          <i className="bi bi-chevron-left"></i>
        </section>
      </div>
  );
}

export default IconPreview;
