import React from 'react';
import { withTranslation } from "react-i18next";
import { BlockPicker } from 'react-color'
import { Tooltip } from '@material-ui/core';

class AppLogoForm extends React.Component {
  constructor(props) {
    super(props);
    var color = ""
    if (props.value) {
      color = props.value
    }
    this.state = {
      backgroundColor: color,
      showPickColor: false
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col">
          <label>{t('custom.background_color_label')}</label>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.background_color_text')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>
        <div className="input-with-color-preview" onClick={this.chanceStatusPickColor}>
          <input
            maxLength={7}
            readOnly={true}
            type="text"
            className="invisible-input"
            onClick={this.chanceStatusPickColor}
            value={this.state.backgroundColor}
            placeholder={t('custom.background_color_place_holder')}
            onChange={this.handleBackgroundColorChange}
          />

          {this.state.backgroundColor && (
            <div
              className="background-color-preview"
              style={{ background: this.state.backgroundColor }}
            />
          )}
        </div>

        {this.state.showPickColor && (
          <div className="block-picker">
            <BlockPicker
              color={(this.state.backgroundColor)}
              onChangeComplete={this.handlePickColor} />
          </div>
        )}
      </div>
    );
  }

  chanceStatusPickColor = (event) => {
    event.preventDefault()
    this.setState({
      showPickColor: !this.state.showPickColor
    });
  };

  handlePickColor = (color) => {
    this.setState({
      backgroundColor: color.hex,
      showPickColor: false
    });
    this.props.handle(color.hex)
  };
}

const AppLogoFormTranslated = withTranslation()(AppLogoForm)

export default AppLogoFormTranslated
