import React from 'react';
import PreviousButton from "../ui/previus-button";
import AppConfigurationFieldForm from "../../partner/app-configuration-field-form";
import AppWebhookForm from "../../partner/app-webhook-form";
import AppLockTypeForm from "../../partner/app-lock-type-form";
import FullLockMockup from "../ui/DeviceMockup/LockTypesMockup/full-lock-mockup";
import PartialLockMockup from "../ui/DeviceMockup/LockTypesMockup/partial-lock-mockup";
import AdsMockup from "../ui/DeviceMockup/LockTypesMockup/ads-mockup";
import Button from "../../../button/button";
import routes from "../../../../../navigation/routes";
import ButtonSavePartner from "../button-save-partner";
import AppKnoxGuardForm from '../../partner/app-knox-guard-form';
import AppWebhookKGFormTranslated from '../../partner/app-webhook-kg-form';

function AdvancedSettings({ step, setStep, updateFormData, formData }) {
  function handleLockTypeChange(lockType) {
    updateFormData("lockType", lockType);
  }

  function handleAppConfigurationChange(config) {
    updateFormData("appConfiguration", config);
  }

  function handleWebhookChange(webhook) {
    updateFormData("webhook", webhook);
  }

  function handleWebhookKGChange(webhookUrlCustomer) {
    updateFormData("webhookUrlCustomer", webhookUrlCustomer);
  }

  function handleKnoxGuardChange(knoxGuard) {
    updateFormData("knoxGuard", knoxGuard);
  }

  const LockTypeComponents = {
    FULL: FullLockMockup,
    PARTIAL: PartialLockMockup,
    ADS_DISPLAY: AdsMockup
  };

  const SelectedLockTypeComponent = LockTypeComponents[formData.lockType];
  const theme =
    formData.lockType === 'PARTIAL' ? (
      "#000000"
    ) : (
      "#FFFFFF"
    );

  return (
    <div className="form-step">
      <section className="step-content-app-visual" style={{ display: "flex", gap: "90px" }}>
        <div style={{ width: '450px' }}>
          {/* LOCK TYPE */}
          <AppLockTypeForm
            value={formData.lockType}
            handle={handleLockTypeChange} />

          {/* CONFIGURATION FIELD */}
          <AppConfigurationFieldForm
            value={formData.appConfiguration}
            handle={handleAppConfigurationChange} />

          {/* WEBHOOK */}
          <AppWebhookForm
            value={formData.webhook}
            handle={handleWebhookChange} />

          {/* WEBHOOK KG*/}
          <AppWebhookKGFormTranslated
            value={formData.webhookUrlCustomer}
            handle={handleWebhookKGChange} />

          {/* KNOX GUARD */}
          <AppKnoxGuardForm
            value={formData.knoxGuard}
            handle={handleKnoxGuardChange} />

          <a href={routes.DOCUMENTATION} target="_blank" rel="noopener noreferrer">
            <Button label={'Documentation'} />
          </a>

        </div>
        {/* <div>
          <DeviceMockup background={theme} body={
            <SelectedLockTypeComponent/>
          }/>
        </div> */}
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />

        <ButtonSavePartner
          updateFormData={updateFormData}
          formData={formData}
        />
      </section>
    </div>
  );
}

export default AdvancedSettings;
