import React from 'react';
import {
  Navbar,
  OverlayTrigger,
  Tooltip,
  Nav,
  Spinner,
  NavDropdown,
  NavbarBrand,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AuthenticationService from 'service/authentication-service';
import logo from 'assets/images/logo.png';
import flag_us from 'assets/images/ic_flag_us.png';
import flag_es from 'assets/images/ic_flag_es.png';
import flag_br from 'assets/images/ic_flag_br.png';
import { withTranslation } from 'react-i18next';

class HeaderOffComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state;
    return newState;
  }

  setNavExpanded(expanded) {
    this.setState({ ...this.state, navExpanded: expanded });
  }

  closeNav() {
    this.setState({ ...this.state, navExpanded: false });
  }

  toggleNavBar() {
    this.setState({ ...this.state, navExpanded: !this.state.navExpanded });
  }

  componentDidMount() {
    AuthenticationService.startListenerRefreshToken();
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Navbar
          className="company-header"
          id="main-header"
          fixed="top"
          expand="lg"
          expanded={this.state.navExpanded}
        >
          <Navbar.Brand className="general-brand">
            <h1>
              <p className="skinny-text">GLOBE</p>TEK
            </h1>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="globe-navbar-nav"
            onClick={() => this.toggleNavBar()}
          >
            <i className="fas fa-bars"></i>
          </Navbar.Toggle>

          <Navbar.Collapse id="globe-navbar-nav">
            <div className="menu">
              <Nav className="me-auto dropdown-text-list menu-margin">
                <NavDropdown
                  title={t('header_off.idioma')}
                  id="collapsible-nav-dropdown"
                  className="dropdown-container"
                >
                  <NavDropdown.Item
                    className="menu-item nav-dropdown-item"
                    onClick={() => i18n.changeLanguage('pt')}
                  >
                    <img
                      className="img-dropdown-header"
                      src={flag_br}
                      width="25"
                      height="20"
                      alt={''}
                    />{' '}
                    PT
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="menu-item nav-dropdown-item"
                    onClick={() => i18n.changeLanguage('es')}
                  >
                    <img
                      className="img-dropdown-header"
                      src={flag_es}
                      width="25"
                      height="20"
                      alt={''}
                    />{' '}
                    ES
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="menu-item nav-dropdown-item"
                    onClick={() => i18n.changeLanguage('en')}
                  >
                    <img
                      className="img-dropdown-header"
                      src={flag_us}
                      width="25"
                      height="20"
                      alt={''}
                    />{' '}
                    EN
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="menu-margin">
                <Nav.Link
                  className="nav-action"
                  id="link-help"
                  href="mailto:support@globetek.zendesk.com"
                >
                  {t('header_off.ajuda')}
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
const HeaderOffComponentTranslated = withTranslation()(HeaderOffComponent);

export default HeaderOffComponentTranslated;
