import React, {useEffect, useState} from 'react';
import "./style/_app.css";
import StoreHeader from "./components/Header/store-header";
import AppDownload from "./components/Download/app-download";
import AppSpecifications from "./components/Specifications/app-specifications";
import StoreFAQ from "./components/FAQ/store-faq";
import InstallationGuide from "./components/Manual/installation-guide";
import {useParams} from "react-router-dom";
import ToastHelper from "../../utils/helpers/toast-helper";
import PartnerService from "../../service/partner-config-service";
import DataHelper from "./utils/helpers/data-helper";
import Logo from "./images/logo.png";
import DateHelper from "../../utils/helpers/date-helper";
import { DotPulse } from '@uiball/loaders'
import LoadingFullscreen from "../../components/ui/loading/loading-fullscreen";

function StoreApp() {
  const {code} = useParams();
  const [data, setData] = useState(null)
  const date = new Date();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    ToastHelper.showLoading(PartnerService.findConfigByCode(code))
      .then((r) => {
        setData(r.data);
        setLoading(false);
      });
  }, [code]);

  return (
    loading ?
      <LoadingFullscreen/>
    :
    <div>
      <StoreHeader partner={DataHelper.name(data) ? DataHelper.name(data) : 'Globetek'}/>
      <main className="app-main">
        <AppDownload
          logo={DataHelper.appIcon(data) ? DataHelper.appIcon(data) : Logo}
          name={DataHelper.name(data) ? DataHelper.name(data) : 'Globetek'}
          category="Security, mobile."
          color={DataHelper.color(data) ? DataHelper.color(data) : '#000000'}
          apk={DataHelper.apkUrl(data) ? DataHelper.apkUrl(data) : 'www.google.com'}
        />

        <AppSpecifications
          os="Android 8 to 13"
          brands="Samsung, Xiaomi LG or Motorola"
          name={DataHelper.name(data) ? DataHelper.name(data) : 'Globetek'}
          size="6,1 MB"
          version="1.0.0"
          lastUpdate={DateHelper.monthInWords(date.getMonth() + 1) + ", " + date.getFullYear()}
        />

        <InstallationGuide/>

        <StoreFAQ primary={DataHelper.color(data) ? DataHelper.color(data) : '#000000'}/>
      </main>
    </div>
  );

}

export default StoreApp;
