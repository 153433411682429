import React from "react";
import ToastHelper from "../../../utils/helpers/toast-helper";
import { getI18n } from 'react-i18next';

function CopyInput(props) {

  let I18n = getI18n();

  return (
    <div className="copy-input" onClick={() => {
      navigator.clipboard.writeText(props.text).then(r => ToastHelper.showInfo(I18n.t('manage.copy_to_clipboard')))
    }}>
      <input className="invisible-input" readOnly value={props.text} />
      <i className="bi bi-clipboard"></i>
    </div>
  );
}

export default CopyInput
