import {getI18n} from "react-i18next";

const NotificationHelper = {

  actions: () => {
    return [
      {value: 'NONE', label: getI18n().t('notification.action_none')},
      {value: 'COPY', label: getI18n().t('notification.action_copy')},
      {value: 'CALL', label: getI18n().t('notification.action_call')},
      {value: 'OPEN', label: getI18n().t('notification.action_open')},
      {value: 'SHARE', label: getI18n().t('notification.action_share')}
    ];
  }
}

export default NotificationHelper;
