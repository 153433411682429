import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';

class AppTemporaryUnlockForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryUnlock: (props.value) ? props.value : {}
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">

        <div style={{ marginBottom: "30px" }} className="two-col" onClick={this.handleEnableShowAdsChange}>
          <label>{t('custom.temporary_unlock_display_ad')}</label>
          <div>
            {this.state.temporaryUnlock.showAds ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        <label>{t('custom.temporary_unlock_times_label')}</label>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={ProductConfigHelper.unlockTimes()}
          onChange={this.handleTimesChange}
          value={this.state.temporaryUnlock.times?.toString()} />

        <label>{t('custom.temporary_unlock_duration_label')}</label>

        <Dropdown
          className="form-select-without-margin-horizontal"
          options={ProductConfigHelper.unlockDurations()}
          onChange={this.handleDurationChange}
          value={this.state.temporaryUnlock.duration?.toString()} />
      </div>
    );
  }

  handleTimesChange = (event) => {
    let obj = this.state.temporaryUnlock
    obj.times = event.value ? parseInt(event.value) : 0

    this.handleTemporyUnlockChange(obj)
  };

  handleDurationChange = (event) => {
    let obj = this.state.temporaryUnlock;
    obj.duration = event.value ? parseInt(event.value) : 0;

    this.handleTemporyUnlockChange(obj)
  };

  handleEnableShowAdsChange = (event) => {

    let isChecked = !this.state.temporaryUnlock.showAds;

    let obj = this.state.temporaryUnlock
    obj.showAds = isChecked

    this.handleTemporyUnlockChange(obj)
  }

  handleTemporyUnlockChange = (obj) => {
    this.setState({
      temporaryUnlock: obj,
    });
    this.props.handle(obj)
  };
}

const AppTemporaryUnlockFormTranslated = withTranslation()(AppTemporaryUnlockForm)

export default AppTemporaryUnlockFormTranslated
