import React from 'react';

function StoreHeader(props) {

  return (
    <header className="app-header">
      <h1>{props.partner}</h1>
    </header>
  );
}

export default StoreHeader;
