import React from 'react';
import LoadingComponent from 'components/ui/loading/loading-component';
import { withTranslation } from 'react-i18next';
import routes from '../../navigation/routes';
import { Link } from 'react-router-dom';
import KnoxDevicesTableTranslated from 'components/tables/knox-devices-table';

class AllKnoxDevices extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;

    if (loading) return <LoadingComponent />
    else {
      return <>
        <div className="demo-container">
          <header style={{ display: 'Flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p className="page-title">{t('knox_devices.title')}</p>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Link to={routes.IMEI} className="nav-link">
                <button className="short-button">{t('knox_devices.new_device')}</button>
              </Link>
              <Link to={routes.BULK_ACTIONS} className="nav-link">
                <button className="short-button">{t('knox_devices.bulk_actions')}</button>
              </Link>
            </div>
          </header>
          <KnoxDevicesTableTranslated />
        </div>
      </>
    }
  }
}

const AllKnoxDevicesTranslated = withTranslation()(AllKnoxDevices);

export default AllKnoxDevicesTranslated;
