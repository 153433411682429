import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';

class AppLockTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lockType: props.value,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <label>{t('manage.lock_type')}</label>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={ProductConfigHelper.lockTypes()}
          onChange={this.handleLockTypeChange}
          value={this.state.lockType}
          placeholder={t('manage.lock_type')} />

      </div>
    );
  }

  handleLockTypeChange = (event) => {
    this.setState({
      lockType: event.value,
    });
    this.props.handle(event.value)
  };
}

const AppLockTypeFormTranslated = withTranslation()(AppLockTypeForm)

export default AppLockTypeFormTranslated
