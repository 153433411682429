import React from 'react';

function NumberLi(props) {
  const li = {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    margin: '20px 0'
  }

  const circle = {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    minHeight: '30px',
    border: 'solid 1px #000000',
    borderRadius: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <li style={li}>
      <div style={circle}>{props.number}</div>
      <div>{props.content}</div>
    </li>
  );
}

export default NumberLi;
