import React from 'react';
import { withTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import PartnerService from '../../../../service/partner-config-service';
import Dropdown from 'react-dropdown';
import CollateralService from '../../../../service/collateral-service';
import ToastHelper from '../../../../utils/helpers/toast-helper';
import { t } from 'i18next';
import LittleLoadingComponent from '../../loading/little-loading-component';

class BillingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      dropDownMonths: [],
      productCodes: [],
      feeValues: [],
      productCode: '',
      discountMonth: '',
      discountValue: {id: -1, value: '', modified: false},
      showDiscount: false,
      showFields: false,
      priceNewActivation: {id: -1, value: '', modified: false},
      priceMaintenance: {id: -1, value: '', modified: false},
      priceMaintenanceGracePeriod: {id: -1, value: '', modified: false},
      periodActivationGrace: {id: -1, value: '', modified: false},
      periodMaintenanceGrace: {id: -1, value: '', modified: false},
      loadingSubmit: false
    };
  }

  componentDidMount() {
    this.getPartnerConfig();
  }

  getPartnerConfig = async () => {
    try {
      let result;

      result = await PartnerService.findAllPartners(0, 500, 'DESC');
      if(result && result.data.content.length > 0) {
        result.data.content.map((item, index) => {
          if(item.productCode !== 'demo')
            this.state.productCodes.push({ value: index, label: item.productCode });
        });
        this.state.productCodes.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ ...this.state, items: result.data.content });
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchFees = async (productCode) => {

    ToastHelper.showLoading(
      CollateralService.findAllFeesByProductCode(productCode),
      {
        error: {
          message: t('toast_helper.try_later'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(result => {

        if(result && result.data) {
          this.setState({ feeValues: result.data })
          const fees = result.data;
          fees.map((fee) => {
            const tuple = {id: fee.id, value: fee.feeValue, modified: false};
            switch(fee.productId){
              case 0:
                this.setState({ priceNewActivation: tuple  });
                break;
              case 1:
                this.setState({ priceMaintenance: tuple });
                break;
              case 2:
                this.setState({ periodActivationGrace: tuple });
                break;
              case 3:
                this.setState({ periodMaintenanceGrace: tuple });
                break;
              case 4:
                this.setState({ priceMaintenanceGracePeriod: tuple });
                break;
            }
          })
        }
        this.setState({ showFields: true });

      })
      .catch(e => {
        console.log(e)
      });
  };

  generateMonths = (dateString) => {
    const startDate = new Date(dateString);
    const currentDate = new Date();

    const monthsArray = [];
    let year = startDate.getFullYear();
    let month = startDate.getMonth();

    while (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month <= currentDate.getMonth())) {

      const yearString = year;
      const monthString = String(month + 1).padStart(2, '0');
      const dropDownString = `${yearString}-${monthString}`;

      monthsArray.push({ value: dropDownString, label: dropDownString });
      month++;
      if (month > 11) {
        month = 0;
        year++;
      }
    }

    return monthsArray;
  };

  handleProductCodeChange = (event) => {
    let createdAt = this.state.items[event.value].createdAt;
    const dropDownMonths = this.generateMonths(createdAt);

    this.setState({
      dropDownMonths: dropDownMonths,
      productCode: event.label,
      discountMonth: '',
      discountValue: {id: -1, value: '', modified: false},
      showDiscount: false,
      showFields: false,
      priceNewActivation: {id: -1, value: '', modified: false},
      priceMaintenance: {id: -1, value: '', modified: false},
      priceMaintenanceGracePeriod: {id: -1, value: '', modified: false},
      periodActivationGrace: {id: -1, value: '', modified: false},
      periodMaintenanceGrace: {id: -1, value: '', modified: false},
      discountMonths: [],
      feeValues: []
    });

    this.fetchFees(event.label);
  };

  handleDiscountMonthChange = (event) => {
    const yearMonth = event.value;
    const fullDateString = `${yearMonth}-01`;
    const dateObject = new Date(fullDateString);
    const isoDateString = dateObject.toISOString().split('T')[0];

    ToastHelper.showLoading(
      CollateralService.getDiscountValueByPeriod(this.state.productCode, isoDateString),
      {
        error: {
          message: t('toast_helper.try_later'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(result => {

        let discount = {id: -1, value: '', modified: false};

        if(result && result.data) {
          discount = {id: result.data.id, value: result.data.subtotal, modified: false};
        }
        this.setState({ discountMonth: yearMonth, discountValue: discount,  showDiscount: true });

      })
      .catch(e => {
        console.log(e)
      });
  };

  handleDiscountValueChange = (event) => {
    let value = event.target.value;
    let modified = true;
    if(this.state.showDiscount === false) {
      value = '';
      modified = false;
    }else
      value = parseFloat(value)

    this.setState((prevState) => ({
      discountValue: {
        ...prevState.discountValue,
        value: value,
        modified: modified,
        discountMonth: this.state.discountMonth
      }
    }));
  }

  handleNewActivationChange = (event) => {
    const newValue = event.target.value;
    this.setState((prevState) => ({
      priceNewActivation: {
        ...prevState.priceNewActivation,
        value: newValue,
        modified: true
      }
    }));
  }

  handlePriceMaintenanceChange = (event) => {
    const newValue = event.target.value;
    this.setState((prevState) => ({
      priceMaintenance: {
        ...prevState.priceMaintenance,
        value: newValue,
        modified: true
      }
    }));
  }

  handlePeriodMaintenanceGraceChange = (event) => {
    let value = event.target.value;
    if(value < 0) {
      value = 0;
    }

    this.setState((prevState) => ({
      periodMaintenanceGrace: {
        ...prevState.periodMaintenanceGrace,
        value: value,
        modified: true
      }
    }));
  }

  handlePriceMaintenanceGracePeriodChange = (event) => {
    const newValue = event.target.value;
    this.setState((prevState) => ({
      priceMaintenanceGracePeriod: {
        ...prevState.priceMaintenanceGracePeriod,
        value: newValue,
        modified: true
      }
    }));
  }

  handlePeriodActivationGraceChange = (event) => {
    let value = event.target.value;
    if(value < 0 || value > 15) {
      value = 0;
    }

    this.setState((prevState) => ({
      periodActivationGrace: {
        ...prevState.periodActivationGrace,
        value: value,
        modified: true
      }
    }));
  }

  saveApps = async () => {

    this.setState({ loadingSubmit: true });

    const body = {
      priceNewActivation : this.state.priceNewActivation,
      priceMaintenance : this.state.priceMaintenance,
      priceMaintenanceGracePeriod : this.state.priceMaintenanceGracePeriod,
      periodActivationGrace : this.state.periodActivationGrace,
      periodMaintenanceGrace : this.state.periodMaintenanceGrace,
      discountValue : this.state.discountValue
    }

    ToastHelper.showLoading(
      CollateralService.updateBillingFields(this.state.productCode, body),
      {
        error: {
          message: t('toast_helper.try_later'),
          notFound: t('toast_helper.not_found'),
        },
        success: t('toast_helper.success'),
        pending: t('toast_helper.please_wait'),
      })
      .then(response => {

        this.setState({
          discountMonth: '',
          discountValue: {id: -1, value: '', modified: false},
          showDiscount: false,
          showFields: false,
          priceNewActivation: {id: -1, value: '', modified: false},
          priceMaintenance: {id: -1, value: '', modified: false},
          priceMaintenanceGracePeriod: {id: -1, value: '', modified: false},
          periodActivationGrace: {id: -1, value: '', modified: false},
          periodMaintenanceGrace: {id: -1, value: '', modified: false},
          feeValues: []
        });

        this.setState({
          loadingSubmit: false
        });

      })
      .catch(e => {
        console.log(e)
        this.setState({ loadingSubmit: false });
      });
  // };
}

  render() {
    const {t} = this.props;
    return (
      <>
        <section className="settings-forms-container">
          <div className="input-container">
            <label>{t('custom.product_code')}</label>
            <Dropdown
              className="form-select-without-margin-horizontal"
              options={this.state.productCodes}
              onChange={this.handleProductCodeChange}
              value={this.state.productCode}
              placeholder={t('custom.product_code')} />
          </div>

          {/* Discount month */}
          <div className="input-container">
            <label>{t('custom.discount_month')}</label>
            <Dropdown
              className="form-select-without-margin-horizontal"
              options={this.state.dropDownMonths}
              onChange={this.handleDiscountMonthChange}
              value={this.state.discountMonth}
              placeholder={t('custom.discount_month')} />
          </div>

          {/* Discount in given month */}
          <div className="input-container">
            <label className="label">{t('custom.discount_value')}</label>
            <input
              type="number"
              step="0.01"
              min={0}
              className="form-input"
              placeholder={t('custom.discount_value')}
              value={this.state.discountValue.value}
              onChange={this.handleDiscountValueChange}
            />
          </div>

          {/* NEW ACTIVATION */}
          {this.state.showFields ? (
            <div className="input-container">
              <label className="label">{t('custom.price_per_new')}</label>
              <input
                type="number"
                step="0.01"
                min="0"
                className="form-input"
                placeholder={t('custom.price_per_new')}
                value={this.state.priceNewActivation.value}
                onChange={this.handleNewActivationChange}
              />
            </div>) : ''}

          {/* MAINTENANCE */}
          {this.state.showFields ? (
            <div className="input-container">
              <label className="label">{t('custom.price_per_maintenance')}</label>
              <input
                type="number"
                step="0.01"
                min={0}
                className="form-input"
                placeholder={t('custom.price_per_maintenance')}
                value={this.state.priceMaintenance.value}
                onChange={this.handlePriceMaintenanceChange}
              />
            </div>) : ''}

          {/* MAINTENANCE Grace Period */}
          {this.state.showFields ? (
            <div className="input-container">
              <label className="label">{t('custom.maintenance_grace_period')}</label>
              <input
                type="number"
                step="1"
                min="0"
                className="form-input"
                placeholder={t('custom.maintenance_grace_period')}
                value={this.state.periodMaintenanceGrace.value}
                onChange={this.handlePeriodMaintenanceGraceChange}
              />
            </div>) : ''}

          {/* MAINTENANCE Grace Period Value */}
          {this.state.showFields ? (
            <div className="input-container">
              <label className="label">{t('custom.price_per_maintenance_grace_period')}</label>
              <input
                type="number"
                step="0.01"
                min={0}
                className="form-input"
                placeholder={t('custom.price_per_maintenance_grace_period')}
                value={this.state.priceMaintenanceGracePeriod.value}
                onChange={this.handlePriceMaintenanceGracePeriodChange}
              />
            </div>) : ''}

          {/* ACTIVATION Grace Period Value */}
          {this.state.showFields ? (
            <div className="input-container">
              <label className="label">{t('custom.activation_grace_period')}</label>
              <input
                type="number"
                step="1"
                min={0}
                max={15}
                className="form-input"
                placeholder={t('custom.activation_grace_period')}
                value={this.state.periodActivationGrace.value}
                onChange={this.handlePeriodActivationGraceChange}
              />
            </div>) : ''}

          {this.state.showFields ? (
          <div className="center-all">
            <button
              type="submit"
              className="short-button"
              onClick={this.saveApps}>
              {t('manage.update')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>

          </div>) : ''}

        </section>
      </>
    );
  }
}

const BillingFormTranslated = withTranslation()(BillingForm)

export default BillingFormTranslated
