import React from 'react';
import {withTranslation} from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      loading: true,
      logo: props.logo,
      currentLogo: props.currentLogo,
    };
  }

  render() {
    const {t} = this.props;
    return (
      <>
        <section className="settings-forms-container">
          {/* Company name */}
          <div className="input-container">
            <label className="label">{t('custom.company_name')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Company address */}
          <div className="input-container">
            <label className="label">{t('custom.company_address')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Company ID */}
          <div className="input-container">
            <label className="label">{t('custom.company_id')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Signed Agreement URL */}
          <div className="input-container">
            <label className="label">{t('custom.agreement')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Administrator name */}
          <div className="input-container">
            <label className="label">{t('custom.administrator_name')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Passport number */}
          <div className="input-container">
            <label className="label">{t('custom.passport_number')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Contact email */}
          <div className="input-container">
            <label className="label">{t('custom.contact_email')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>

          {/* Contact number */}
          <div className="input-container">
            <label className="label">{t('custom.contact_number')}</label>
            <input
              type="text"
              className="form-input"
              value={this.state.logo}
              onChange={this.handleLogoChange}
            />
          </div>
        </section>
      </>
    );
  }
}

const RegistrationFormTranslated = withTranslation()(RegistrationForm)

export default RegistrationFormTranslated
