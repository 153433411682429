import React, { useEffect } from 'react';
import {withTranslation} from "react-i18next";
import * as Authentication from 'utils/helpers/authentication-helper';
import { useHistory } from 'react-router-dom';
import routes from 'navigation/routes';

function Error401(props) {
  const { t } = props;
  const navigate = useHistory();

  useEffect(() => {
    /*Redirects to login page after 5 seconds*/
    const timer = setTimeout(() => {
      Authentication.logout();
      window.location = routes.LOGIN;
    }, 5000); /*5 seconds for the customer to be redirected to the login screen*/

    return () => clearTimeout(timer); // Clears the timer if the component dismounts
  }, [navigate]);

    //Redirects to login page manually
    const handleLogout = () => {
    Authentication.logout(); // Claear te session
    window.location = routes.LOGIN; // Manually redirects to login page
  };

  return (
    <div style={styles.container}>
      <p className="page-title">{t('error_401.detected_an_error')}</p>
      <button className="short-button" onClick={handleLogout} style={styles.button}>
        {t('aside.logout')}
      </button>
    </div>
  );
};

// Adjusted styles for the 401 error screen
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
  },
  errorTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '50px',
    margin: '0 auto',
  },
  errorMessage: {
    fontSize: '15px',
    marginBottom: '26px',
  },
  button: {
      padding: '12px 24px',
      fontSize: '18px',
      cursor: 'pointer',
      backgroundColor: '#000',
      color: '#fff',
      border: 'none',
      borderRadius: '24px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'box-shadow 0.2s ease-in-out',
      margin: '0 auto',
      display: 'inline-block',
  },
  buttonHover: {
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
  },
};

//Applying HOC withTranslation to add translation support
export default withTranslation()(Error401);
