import React, { useState, useRef } from "react";
import Chevron from "./chevron"

function Accordion(props) {
  let expand = false
  if(props.expanded){
    expand = true
  }
  const [setActive, setActiveState] = useState(expand ? "active" : "");
  const [setHeight, setHeightState] = useState(expand ? "10000px" : "0");
  const [setRotate, setRotateState] = useState(expand ? "accordion-icon rotate" : "accordion-icon");
  const [setRotate45, setRotateState45] = useState(expand ? "accordion-icon rotate-45" : "accordion-icon");

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0" : `10000px`);
    setRotateState(setActive === "active" ? "accordion-icon" : "accordion-icon rotate");
    setRotateState45(setActive === "active" ? "accordion-icon" : "accordion-icon rotate-45");
  }

  return (
    <div className={props.active ? "accordion-section" : "accordion-section disabled"}>
      <button className={`accordion ${setActive}`} onClick={props.active ? toggleAccordion : null}>
        <p className="accordion-title">{props.title}</p>
        {props.plus ? <i className={`${setRotate45} bi bi-plus`}></i> :
          <Chevron className={`chevron ${setRotate}`} width={13} fill={props.customColor ? props.customColor : props.active ? "#171717" : "#929292"} />
        }
      </button>
      <div style={{maxHeight: `${setHeight}`}} className="accordion-content">
        <div className="accordion-text" >{props.content}</div>
      </div>
    </div>
  );
}

export default Accordion;
