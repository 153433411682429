import { RSAA } from 'redux-api-middleware';

export const PRINCIPAL_SELECT = '/loan/principal/select';
export const PRINCIPAL_SELECT_SUCCESS = '/loan/principal/select/success';
export const PRINCIPAL_SELECT_FAILURE = '/loan/principal/select/failure';

export const SUBMIT_NEW = '/loan/application/new';
export const SUBMIT_NEW_SUCCESS = '/loan/application/new/success';
export const SUBMIT_NEW_FAILURE = '/loan/application/new/failure';

export const SUBMIT_BASIC = '/loan/application/basic';
export const SUBMIT_BASIC_SUCCESS = '/loan/application/basic/success';
export const SUBMIT_BASIC_FAILURE = '/loan/application/basic/failure';

export const SUBMIT_PRE_CONTRACT = '/loan/application/contract/pre';
export const SUBMIT_PRE_CONTRACT_SUCCESS =
  '/loan/application/contract/pre/success';
export const SUBMIT_PRE_CONTRACT_FAILURE =
  '/loan/application/contract/pre/failure';

export const SIGN_CONTRACT = '/loan/application/contract';
export const SIGN_CONTRACT_SUCCESS = '/loan/application/contract/success';
export const SIGN_CONTRACT_FAILURE = '/loan/application/contract/failure';

export const SUBMIT_BANK_STATEMENT = '/loan/application/income/document';
export const SUBMIT_BANK_STATEMENT_SUCCESS =
  '/loan/application/income/document/success';
export const SUBMIT_BANK_STATEMENT_FAILURE =
  '/loan/application/income/document/failure';

export const CONTINUE_LOAN_APPLICATION = '/loan/application/continue';
export const CONTINUE_LOAN_APPLICATION_SUCCESS =
  '/loan/application/continue/success';
export const CONTINUE_LOAN_APPLICATION_FAILURE =
  '/loan/application/continue/failure';

export const LOAN_CONTRACT = '/loan/application/contract';
export const LOAN_CONTRACT_SUCCESS = '/loan/application/contract/success';
export const LOAN_CONTRACT_FAILURE = '/loan/application/contract/failure';

export const SUBMIT_POST_CONTRACT = '/loan/application/contract/post';
export const SUBMIT_POST_CONTRACT_SUCCESS =
  '/loan/application/contract/post/success';
export const SUBMIT_POST_CONTRACT_FAILURE =
  '/loan/application/contract/post/failure';

export const SUBMIT_PROMOTIONAL_CODE =
  '/loan/application/contract/promotional-code';
export const SUBMIT_PROMOTIONAL_CODE_SUCCESS =
  '/loan/application/contract/promotional-code/success';
export const SUBMIT_PROMOTIONAL_CODE_FAILURE =
  '/loan/application/contract/promotional-code/failure';

/**
 * Selects the principal for a loan.
 */
export function selectPrincipal(principal, proceed = false, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/*/application/principal`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: principal,
      types: [
        PRINCIPAL_SELECT,
        { type: PRINCIPAL_SELECT_SUCCESS, meta: { proceed: proceed } },
        PRINCIPAL_SELECT_FAILURE,
      ],
    },
  };
}

/**
 * Submits a new application.
 */
export function submitNew(application, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/*/application/new`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: JSON.stringify(application).replace(/"\s+|\s+"/g, '"'),
      types: [SUBMIT_NEW, SUBMIT_NEW_SUCCESS, SUBMIT_NEW_FAILURE],
    },
  };
}

/**
 * Submits a basic application.
 */
export function submitBasic(application, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/*/application/basic`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: JSON.stringify(application).replace(/"\s+|\s+"/g, '"'),
      types: [SUBMIT_BASIC, SUBMIT_BASIC_SUCCESS, SUBMIT_BASIC_FAILURE],
    },
  };
}

/**
 * Submits a pre-contract application.
 */
export function submitPreContract(loanId, application, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/${loanId}/application/contract/pre`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: JSON.stringify(application).replace(/"\s+|\s+"/g, '"'),
      types: [
        SUBMIT_PRE_CONTRACT,
        SUBMIT_PRE_CONTRACT_SUCCESS,
        SUBMIT_PRE_CONTRACT_FAILURE,
      ],
    },
  };
}

/**
 * Signs an application contract.
 */
export function signContract(loanId, device, validationCode) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/${loanId}/application/contract?code=${validationCode}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      types: [SIGN_CONTRACT, SIGN_CONTRACT_SUCCESS, SIGN_CONTRACT_FAILURE],
    },
  };
}

/**
 * Submits bank statements.
 */
export function submitBankStatement(loanId, files, device) {
  let data = new FormData();
  files.forEach((file) => data.append('documents', file));
  return {
    [RSAA]: {
      endpoint: `/api/loan/${loanId}/application/bank-statement`,
      method: 'PUT',
      headers: {
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: data,
      types: [
        SUBMIT_BANK_STATEMENT,
        SUBMIT_BANK_STATEMENT_SUCCESS,
        SUBMIT_BANK_STATEMENT_FAILURE,
      ],
    },
  };
}

/**
 * Submits a post-contract application.
 */
export function submitPostContract(loanId, application, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/${loanId}/application/contract/post`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      body: JSON.stringify(application).replace(/"\s+|\s+"/g, '"'),
      types: [
        SUBMIT_POST_CONTRACT,
        SUBMIT_POST_CONTRACT_SUCCESS,
        SUBMIT_POST_CONTRACT_FAILURE,
      ],
    },
  };
}

/**
 * Finds the current loan for a customer.
 */
export function continueApplication(customerTaxId, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/*/application/continue?taxId=${customerTaxId}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      types: [
        CONTINUE_LOAN_APPLICATION,
        CONTINUE_LOAN_APPLICATION_SUCCESS,
        CONTINUE_LOAN_APPLICATION_FAILURE,
      ],
    },
  };
}

/**
 * Get contract info
 */
export function getContract(loanId) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/${loanId}/application/contract`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [LOAN_CONTRACT, LOAN_CONTRACT_SUCCESS, LOAN_CONTRACT_FAILURE],
    },
  };
}

/*
 * send promotional code by URL
 */
export function submitPromotionalCode(promotionalCode, loanId, device) {
  return {
    [RSAA]: {
      endpoint: `/api/loan/application/promotional-code?promotionalCode=${promotionalCode}&loanId=${loanId}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({ device: device }),
      },
      types: [
        SUBMIT_PROMOTIONAL_CODE,
        SUBMIT_PROMOTIONAL_CODE_SUCCESS,
        SUBMIT_PROMOTIONAL_CODE_FAILURE,
      ],
    },
  };
}
