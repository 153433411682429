import React from 'react';
import AuthenticationService from 'service/authentication-service';
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ToastHelper from "../../utils/helpers/toast-helper";
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import ValidationHelper from 'utils/helpers/validation-helper';

class RequestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      loadingSubmit: false,
      requestSuccess: false,
    };
  }

  onChangeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  requestPassword = async (e) => {
    e.preventDefault()

    const { t } = this.props;
    const email = this.state.username;

    if (!ValidationHelper.checkValidity(email, { email: true })) {
      ToastHelper.showWarning(t('toast_helper.email_invalid'));
      return
    }

    this.setState({ loadingSubmit: true });

    try {

      await ToastHelper.showLoading(
        AuthenticationService.forgotPassword(email),
        {
          error: {
            message: t('toast_helper.request_password_fail'),
            notFound: t('toast_helper.partner_not_found'),
          },
          success: t('toast_helper.requested_password'),
          pending: t('toast_helper.requesting_password'),
        }
      );
    } catch (error) {
      console.error(error);
    }

    this.setState({ loadingSubmit: false });

  };

  render() {
    const { requestSuccess } = this.state;
    const { t, i18n } = this.props;
    return (
      <div className="login-container">
        <form id="loginForm" className="form glass-effect" onSubmit={e => this.requestPassword(e)}>
          <br /><br />
          <label>
            {t('login_reset_password.email_reset')}
          </label>

          <input type="text"
            name="username"
            className="form-input remove-bg"
            value={this.state.username}
            onChange={this.onChangeUsername}
            required
          />

          <div>
            <button
              type="submit"
              className="form-button button-login"
            >
              {t('login_reset_password.request')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>
          </div>

          <br /><br />
          <NavLink
            to={'/'}
            type="button"
            className="btn mt-3"
            title={t('login_reset_password.back_login_page')}
          >
            <i className="fas fa-long-arrow-alt-left"></i> {t('login_reset_password.back')}
          </NavLink>
        </form>
      </div>
    );
  }
}
const RequestPasswordTranslated = withTranslation()(RequestPassword)

export default RequestPasswordTranslated
