import React, {useState} from 'react';
import NextButton from "../ui/next-button";

import {getI18n} from "react-i18next";

function BasicInformation({ step, setStep, updateFormData, formData}) {
  const I18n = getI18n()

  function handleChange(propsName, event) {
    updateFormData(propsName, event.target.value);
  }

  return (
    <div className="form-step">
      <section className="step-content">

        {/* COMPANY NAME */}
        <div className="input-container" style={{width: '450px'}}>
          <label className="label">{I18n.t('custom.company_name')} *</label>
          <input
            value={formData.name}
            type="text"
            maxLength={40}
            className="form-input"
            onChange={(event) => handleChange("name", event)}
          />
        </div>

        {/* EMAIL */}
        <div className="input-container">
          <label>{I18n.t('custom.email_label')} *</label>
          <input
            value={formData.email}
            type="email"
            className="form-input"
            onChange={(event) => handleChange("email", event)}
          />
        </div>

        {/* ACTIVATION FEE */}
        <div className="input-container">
          <label className="label">{I18n.t('manage_contract.activation_value')}</label>
          <input
            value={formData.activationFee}
            type="number"
            className="form-input"
            onChange={(event) => handleChange("activationFee", event)}
          />
        </div>

        {/* MAINTENANCE FEE */}
        <div className="input-container">
          <label className="label">{I18n.t('manage_contract.maintenance_value')}</label>
          <input
            value={formData.maintenanceFee}
            type="number"
            className="form-input"
            onChange={(event) => handleChange("maintenanceFee", event)}
          />
        </div>
      </section>
      <section className="step-controls">
        <NextButton step={step} setStep={setStep}/>
      </section>
    </div>
  );
}

export default BasicInformation;
