import React from 'react';
import { getI18n, withTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import CollateralHelper from "../../../utils/helpers/collateral-helper";
import DateHelper from "../../../utils/helpers/date-helper";
import ToastHelper from "../../../utils/helpers/toast-helper";
import CollateralService from "../../../service/collateral-service";
import PartnerConfigService from "../../../service/partner-config-service";
import 'react-tooltip/dist/react-tooltip.css';
import CurrencyFormat from 'react-currency-format';
import { IconButton, Tooltip } from '@material-ui/core';
import Cookies from "js-cookie";

class ManageCollateral extends React.Component {
  I18n = getI18n();

  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      isDev: process.env.REACT_APP_ENV === 'dev',
      collateral: props.collateral,
      isKnoxGuard: CollateralHelper.isKnoxGuard(props.collateral),
      offlineCode: '',
      dateTime: '',
      tag: CollateralHelper.executorItemId(props.collateral),
      newDueDate: props.collateral.dueDate,
      newDueDateSelected: null,
      newProductItemId: CollateralHelper.productItemId(this.props.collateral),
      newProductCode: CollateralService.findAllFeesByProductCode(props.collateral),
      executorItemId: CollateralHelper.executorItemId(props.collateral),
      passkey: '',
      newUserId: CollateralHelper.userId(this.props.collateral),
      newLockType: null,
      devicePrice: 0,
      partnerConfigLockType: null,
      isLocked: props.collateral.state == 2,
      lockMessage: CollateralHelper.lockMessage(props.collateral),
      lockMessageAux: CollateralHelper.lockMessage(props.collateral),
      scheduledLock: !this.isLocked && CollateralHelper.lockIsScheduled(props.collateral.dueDate)
    };
  }

  componentDidMount() {
    this.getPartnerConfigLockType();

    if (!this.state.lockMessage) {
      this.getPartnerConfigLockMessage();
    }
  }

  // {/* Method using passkey*/}
  getKnoxGuardReleaseCode = async (event) => {
    event.preventDefault();
    const { executorItemId, passkey } = this.state;

    if (!executorItemId || !passkey) {
      ToastHelper.showWarning(this.I18n.t('manage.insert_passkey'));
      return;
    }

    try {
      const response = await CollateralService.getKnoxGuardReleaseCode(executorItemId, passkey);
      this.setState({ additionalOfflineCode: response.data });
      ToastHelper.showSuccess(this.I18n.t('toast_helper.release_code_generated'));
    } catch (error) {
      console.error(error);
      ToastHelper.showError(this.I18n.t('toast_helper.release_code_failed'));
    }
  };

  getPartnerConfigLockType = async () => {
    try {
      const response = await PartnerConfigService.getLockType();
      this.setState({
        partnerConfigLockType: response.data
      });
    } catch (error) {
      console.error(error);
    }
  };

  getPartnerConfigLockMessage = async () => {
    try {
      const response = await PartnerConfigService.findPartnerConfig();
      const message = response?.data?.lockOptions?.lockMessage ?? ""
      this.setState({
        lockMessage: message,
        lockMessageAux: message
      });
    } catch (error) {
      console.error(error);
    }
  };

  releaseCollateral = (event) => {
    event.preventDefault()
    ToastHelper.showLoading(CollateralService.releaseCollateral(this.state.tag), {
      pending: this.I18n.t('toast_helper.releasing_device'),
      success: this.I18n.t('toast_helper.device_released'),
      error: {
        notFound: this.I18n.t('toast_helper.not_found'),
      }
    })
      .then(response => {
        this.setState({
          collateral: response.data,
          littleLoading: '',
          showConfirmation: '',
        });
      })
      .catch(e => {
        this.setState({ littleLoading: '' });
      });
  }

  getOfflineCode = (event) => {
    event.preventDefault()
    ToastHelper.showLoading(
      CollateralService.getReleaseCode(
        CollateralHelper.executorItemId(this.state.collateral),
        this.state.dateTime ? this.state.dateTime : null
      ),
      {
        pending: this.I18n.t('toast_helper.generating_release_code'),
        success: this.I18n.t('toast_helper.release_code_generated'),
        error: {
          notFound: this.I18n.t('toast_helper.release_code_failed'),
          conflict: this.I18n.t('toast_helper.state_invalid')
        },
      }
    )
      .then((response) => {
        this.setState({
          offlineCode: response.data,
          littleLoading: '',
        });
      })
      .catch((e) => {
        this.setState({ littleLoading: '' });
      });
  };

  getDevicePrice = async (event) => {
    event.preventDefault()

    // Get mean, BRL coin
    let device = CollateralHelper.device(this.state.collateral)
    let brlPrice = (await CollateralService.getMean(device)).data.toString()

    if (brlPrice > 0) {
      // Get dollar
      if (Cookies.get('dollar') == null) {
        const dollar = await CollateralService.getLatestCurrency()
        Cookies.set('dollar', dollar.data)
      }
      let dollar = Cookies.get('dollar')

      // Converted to dollar
      let convertedPrice = parseInt(brlPrice.toString()) / parseInt(dollar.toString())

      // Set converted value
      this.setState({
        devicePrice: convertedPrice,
      })
    } else {
      let I18n = getI18n()
      ToastHelper.showWarning(I18n.t('manage.information_not_available'));
    }
  };

  updateCollateral = (event) => {

    event.preventDefault()

    if (this.state.newDueDateSelected || this.state.newProductItemId || this.state.newLockType || this.state.newUserId || this.state.newProductCode || this.state.lockMessage != this.state.lockMessageAux) {

      const fields = {}

      if (this.state.newDueDateSelected) {
        fields["newDueDate"] = this.state.newDueDateSelected
      }

      if (this.state.newProductItemId) {
        fields["productItemId"] = Number(this.state.newProductItemId)
      }

      if (this.state.newProductCode) {
        fields["productCode"] = Number(this.state.newProductCode)
      }

      if (this.state.newLockType) {
        fields["lockType"] = this.state.newLockType
      }

      if (this.state.newUserId) {
        fields["userId"] = this.state.newUserId
      }

      // clear lockMessage
      if (!this.state.isLocked) {
        fields["lockMessage"] = ""
      } else if (this.state.lockMessage != this.state.lockMessageAux) {
        fields["lockMessage"] = this.state.lockMessage
      }

      let I18n = getI18n()
      ToastHelper.showLoading(CollateralService.updateFields(this.state.tag, fields), {
        pending: this.I18n.t('toast_helper.updating_collateral_data'),
        success: this.I18n.t('toast_helper.updated_collateral_data'),
        error: {
          message: I18n.t('toast_helper.update_collateral_data_fail_message'),
          notFound: I18n.t('toast_helper.not_found'),
        },
      })
        .then(response => {
          this.setState({
            collateral: response.data,
            littleLoading: '',
            showConfirmation: '',
          });

          if (response) {
            this.setState({
              newLockType: null,
              newDueDateSelected: null,
              newProductItemId: null,
              newProductCode: null
            });
          }
        })
        .catch(e => {
          this.setState({
            littleLoading: '',
          })
        });
    }
  }

  handleIsLockedChange = (event) => {

    let isChecked = !this.state.isLocked

    this.setState({ isLocked: isChecked });

    let date = new Date();

    if (isChecked) {
      date.setDate(date.getDate() - 1);
    } else {
      date.setFullYear(CollateralHelper.infiniteYear());
    }

    this.setState({
      newDueDateSelected: DateHelper.getFormattedDateWithFormat(date, 'YYYY-MM-DD'),
      scheduledLock: false
    });
  }

  handlLockIsScheduledChange = (event) => {
    let isChecked = !this.state.scheduledLock

    let date = new Date();

    if (isChecked) {
      date.setMonth(date.getMonth() + 1);
    } else {
      date.setFullYear(CollateralHelper.infiniteYear());
    }

    this.setState({
      newDueDateSelected: DateHelper.getFormattedDateWithFormat(date, 'YYYY-MM-DD'),
      scheduledLock: isChecked
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <p className="page-title">{t('manage.your_collateral')}</p>
        <div className="group-forms">
          <form className="form" onSubmit={(event) => this.updateCollateral(event)}>
            <p className="page-title">{t('manage.edit_collateral')}</p>

            {/* Device Price */}
            <label>{t('manage.device_price')}</label>
            <div className="two-col">
              <CurrencyFormat className="form-input readonly input-shorter" value={this.state.devicePrice} displayType={'text'}
                thousandSeparator={true} prefix={'$'} />
              <button className="form-button round-button"
                onClick={e => this.getDevicePrice(e)}><i className="bi bi-arrow-clockwise"></i></button>
            </div>

            {/* Collateral ID */}
            <label>{t('manage.collateral_id')}</label>
            <input className="form-input readonly" value={CollateralHelper.id(this.state.collateral)} />

            {/* User ID */}
            <label>{t('manage.user_id')}</label>
            <input className="form-input"
              value={this.state.newUserId}
              placeholder='N/A'
              onChange={
                (e) => {
                  this.setState({
                    newUserId: e.target.value
                  })
                }}
            />

            {/* Lock device */}
            <div className="two-col" style={{ marginBottom: "10px" }}>
              <label>{this.state.isLocked ? t('manage.locked') : t('manage.unlocked')}</label>
              <div onClick={this.handleIsLockedChange} >
                {this.state.isLocked ? (
                  <i className="bi bi-toggle-on" />
                ) :
                  <i className="bi bi-toggle-off" />
                }
              </div>
            </div>

            {this.state.isKnoxGuard && this.state.isLocked ?
              <div>
                <label>{t('custom.customize_lock_message_label')}</label>
                <textarea className="form-input-textarea"
                  value={this.state.lockMessage}
                  rows={5}
                  placeholder='N/A'
                  onChange={
                    (e) => {
                      this.setState({
                        lockMessage: e.target.value
                      })
                    }}
                />
              </div>
              : <div />
            }


            <label>{t('manage.intern_id')}</label>
            <input type="number" className="form-input"
              value={this.state.newProductItemId}
              placeholder={t('manage.id_placeholder')}
              onChange={
                (e) => {
                  this.setState({
                    newProductItemId: e.target.value
                  })
                }}
            />

            <div>
              <button className="form-button" type="submit">{t('manage.update')}</button>
            </div>
          </form>

          {this.state.isKnoxGuard ?
            <div className="two-col">


              {/* New Options Screen */}
              <form className="form">
                <p className="page-title">{t('manage.knox')}</p>

                {/* Passkey */}
                <label>{t('manage.passkey')}</label>
                <input type="number" className="form-input"
                  value={this.state.passkey}
                  placeholder={t('manage.insert_passkey')}
                  onChange={(e) => this.setState({ passkey: e.target.value })}
                />

                {/* Additional Offline code */}
                <label>{t('manage.offline_code')}</label>
                <div className="two-col">
                  <div className="copy-input" onClick={() => {
                    navigator.clipboard.writeText(this.state.additionalOfflineCode).then(r => ToastHelper.showInfo(t('manage.copy_to_clipboard')));
                  }}>
                    <input className="invisible-input readOnly" value={this.state.additionalOfflineCode} />
                    <i className="bi bi-clipboard"></i>
                  </div>
                  <button className="form-button round-button"
                    onClick={e => this.getKnoxGuardReleaseCode(e)}><i className="bi bi-arrow-clockwise"></i></button>
                </div>

                <div className="two-col">
                  <button className="form-button"
                    onClick={e => this.releaseCollateral(e)}>{t('manage.release')}</button>
                  <Tooltip style={{ backgroundColor: 'transparent' }} title={t('manage.release_info')} placement="top">
                    <IconButton>
                      <i className="bi bi-info-circle-fill"></i>
                    </IconButton>
                  </Tooltip>
                </div>

              </form>

            </div>

            : <div className="two-col">

              <form className="form">
                <p className="page-title">{t('manage.other_actions')}</p>

                {/* Date for offline code */}
                <label>{t('manage.offline_code')}</label>
                <div className="two-col">
                  <input className="form-input" type="date"
                    onChange={
                      (e) => {
                        this.setState({
                          dateTime: e.target.value
                        })
                      }}
                  />
                  <Tooltip style={{ backgroundColor: 'transparent' }} title={t('manage.date_for_offline_code')} placement="top">
                    <IconButton>
                      <i className="bi bi-info-circle-fill"></i>
                    </IconButton>
                  </Tooltip>
                </div>

                {/* Offline code */}
                <label>{t('manage.offline_code')}</label>
                <div className="two-col">
                  <div className="copy-input" onClick={() => {
                    navigator.clipboard.writeText(this.state.offlineCode).then(r => ToastHelper.showInfo(t('manage.copy_to_clipboard')))
                  }}>
                    <input className="invisible-input readOnly" value={this.state.offlineCode} />
                    <i className="bi bi-clipboard"></i>
                  </div>
                  <button className="form-button round-button"
                    onClick={e => this.getOfflineCode(e)}><i className="bi bi-arrow-clockwise"></i></button>
                </div>

                <div className="two-col">
                  <button className="form-button"
                    onClick={e => this.releaseCollateral(e)}>{t('manage.release')}</button>
                  <Tooltip style={{ backgroundColor: 'transparent' }} title={t('manage.release_info')} placement="top">
                    <IconButton>
                      <i className="bi bi-info-circle-fill"></i>
                    </IconButton>
                  </Tooltip>
                </div>
              </form>

            </div>
          }

        </div>
      </>
    );
  }
}

const ManageCollateralTranslated = withTranslation()(ManageCollateral);

export default ManageCollateralTranslated;
