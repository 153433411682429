import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import ToastHelper from 'utils/helpers/toast-helper';
import PartnerConfigService from '../../../../service/partner-config-service';

class AppsManagementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedApps: this.convertArrayToString(props.value?.allowedApplications),
      blockedApps: this.convertArrayToString(props.value?.blackListApplications),
      loadingSubmit: false
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div>

          <p>{t('custom.allowed_apps')}</p>

          <textarea
            className="form-input-textarea"
            value={this.state.allowedApps}
            rows={5}
            onChange={this.handleAllowedAppsChange} />

          <p>{t('custom.blocked_apps')}</p>

          <textarea
            className="form-input-textarea"
            value={this.state.blockedApps}
            rows={5}
            onChange={this.handleBlockedAppsChange} />

          <div className="center-all">
            <button
              type="submit"
              className="short-button"
              onClick={this.saveApps}>
              {t('manage.update')}
              {this.state.loadingSubmit ? <LittleLoadingComponent /> : null}
            </button>

          </div>
        </div>
      </div>
    );
  }

  convertArrayToString(apps) {
    try {

      if (!apps) return ""

      return apps?.join(', ');

    } catch (error) {
      console.log(error)
      return "";
    }
  }

  convertStringToArray(apps) {
    try {

      if (!apps?.trim()) return null

      return apps?.trim().split(',').map(item => this.extractPackageName(item.trim())).filter((item, index, self) => self.indexOf(item) === index)

    } catch (error) {
      console.log(error)
      return null;
    }
  }

  handleAllowedAppsChange = (event) => {
    this.setState({
      allowedApps: event.target.value,
    });
  };

  handleBlockedAppsChange = (event) => {
    this.setState({
      blockedApps: event.target.value,
    });
  };

  extractPackageName(pkg) {

    const regex = /=(.*)$/;
    const match = pkg.match(regex);

    var cleanedPkg = pkg;

    if (match) cleanedPkg = match[1];

    const regexCaracter = /[^A-Za-z0-9.]/g;
    return cleanedPkg.replace(regexCaracter, '')
  }

  saveApps = async () => {

    const { t } = this.props;

    this.setState({ loadingSubmit: true });

    const allowedApps = this.convertStringToArray(this.state.allowedApps);
    const blockedApps = this.convertStringToArray(this.state.blockedApps);

    const body = {
      allowedApps: allowedApps,
      blockedApps: blockedApps
    }

    ToastHelper.showLoading(
      PartnerConfigService.updateAllowedApps(body),
      {
        error: {
          message: t('toast_helper.update_generic_fail_message'),
          notFound: t('toast_helper.partner_not_found'),
        },
        success: t('toast_helper.updated_generic'),
        pending: t('toast_helper.updating_generic'),
      })
      .then(response => {

        this.setState({
          loadingSubmit: false
        });

        if (response) {
          this.setState({
            allowedApps: this.convertArrayToString(allowedApps),
            blockedApps: this.convertArrayToString(blockedApps)
          });
        }

      })
      .catch(e => {
        console.log(e)
        this.setState({ loadingSubmit: false });
      });
  };
}

const AppsManagementFormTranslated = withTranslation()(AppsManagementForm)

export default AppsManagementFormTranslated
