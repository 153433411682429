let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ReportHelper = {
  quantity: (report) => {
    return report
      && report.quantity ? report.quantity : "0";
  },
  total: (report) => {
    return report
      && report.value
      && USDollar.format(report.value)
  },
}

export default ReportHelper;
