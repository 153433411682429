import React from 'react';
import PropTypes from 'prop-types';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section className="component not-found-container">
        <div className="text-center mt-5">
          <h1 className="text-primary">404</h1>
          <p>Página não encontrada.</p>
          <small>Verifique se você digitou uma url correta.</small>
        </div>
      </section>
    );
  }
}

NotFound.propTypes = {
  staticContext: PropTypes.object,
};

NotFound.defaultProps = {
  staticContext: {
    status: '404',
  },
};
