import React, {useEffect, useState} from 'react';
import 'react-dropdown/style.css';
import {withTranslation} from "react-i18next";
import Dropdown from "react-dropdown";
import IconInput from "../ui/icon-input/icon-input";
import CurrentStep from "../utils/enum/current-step";
import ProductConfigHelper from "../../utils/helpers/product-config-helper";
import PaginationTranslated from "../ui/pagination/pagination";
import ToastHelper from "../../utils/helpers/toast-helper";
import PartnerService from "../../service/partner-config-service";
import PartnerConfigService from "../../service/partner-config-service";
import Button from "../ui/button/button";
import FadeIn from "react-fade-in";
import UpdatePartnerTranslated from "../ui/form/update-partner/update-partner";

function PartnersTable() {

  /* CURRENT PARTNER TO EDIT */
  const [partner, setPartner] = useState(false)

  /* PAGINATION VALUES */
  const itemsOption = ["3", "5", "10", "20"];
  const [loading, setLoading] = useState("")
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(itemsOption[1])
  const [totalNumber, setTotalNumber] = useState(0)

  /* FILTER BY NAME VALUES */
  const [partnerNameFilter, setPartnerNameFilter] = useState("")

  /* FILTER BU STEP VALUES */
  const steps = Object.values(CurrentStep)
  steps.unshift("All");
  const [stepFilter, setStepFilter] = useState(steps[0])

  const findKeyByValue = (object, value) => {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null;
  };

  function searchByName(event) {
    event.preventDefault()

    if (partnerNameFilter !== "") {
      ToastHelper.showLoading(PartnerService.findByName(currentPage, itemsPerPage, 'DESC', partnerNameFilter), {
        error: {
          notFound: 'Not found'
        },
      })
        .then(response => {
          setItems(response.data.content);
          setTotalNumber(response.data.totalElements);
        })
        .catch(e => {
          console.log(e)
          setItems(null)
        });
    } else {
      // clear the filter
    }
  }

  async function approve(productCode) {
    try {
      await ToastHelper.showLoading(
        PartnerConfigService.approve(productCode),
        {
          error: {
            message: 'Failed'
          },
          success: 'Approved',
          pending: 'Please, wait..',
        }
      ).then(() => setCurrentPage(currentPage))
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result;

        if (stepFilter === "All" && partnerNameFilter === "") {
          result = await PartnerService.findAllPartners(currentPage, itemsPerPage, 'DESC');
        } else {
          const stepKey = findKeyByValue(CurrentStep, stepFilter)
          result = await PartnerService.findByStep(currentPage, itemsPerPage, 'DESC', stepKey);
        }

        setItems(result.data.content);
        setTotalNumber(result.data.totalElements);
        setLoading("");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, stepFilter, partnerNameFilter]);


  return (
    partner ?
      <>
        <FadeIn>
          <UpdatePartnerTranslated partner={partner}/>
          <p className="page-title accent pointer" onClick={() => setPartner(false)}>
            <i className="bi bi-chevron-left"></i>
          </p>
        </FadeIn>
      </>
      :
      <>
        <section style={{display: 'flex', gap: '20px'}}>
          {/* Filter by partner */}
          <IconInput
            type="text"
            placeholder="Partner name"
            icon="bi bi-search"
            onClick={(event) => searchByName(event)}
            onChange={(event) => setPartnerNameFilter(event.target.value)}
          />

          {/* Filter by step */}
          <div style={{display: 'flex', alignItems: 'center', color: 'grey'}}>
            <p>Step</p>
            <Dropdown className="form-select dropdown-large" options={steps}
                      onChange={(event) => setStepFilter(event.value)}
                      value={steps[0]} placeholder="Select an option"/>
          </div>

          <Dropdown className="form-select" options={itemsOption} onChange={(event) => setItemsPerPage(event.value)}
                    value={itemsPerPage} placeholder="Select an option"/>
        </section>

        {/* Table */}
        <div className="table-container">
          <table className="custom-table">
            <thead>
            <tr>
              <td>Partner</td>
              <td>Created at</td>
              <td>Status</td>
              <td></td>
              {/*
              TODO: Link to edit partner config
            <td></td>
            */}

            </tr>
            </thead>
            <tbody className="custom-tbody">
            {items.map(item => {
              return <tr key={item.id}>
                <td>{ProductConfigHelper.partnerName(item) || "N/A"}</td>
                <td>{ProductConfigHelper.createdAt(item, true) || "N/A"}</td>
                <td>{ProductConfigHelper.step(item) || "N/A"}</td>
                {
                  ProductConfigHelper.isWaitingForApproval(item) ?
                    <td><Button label={"approve"} color={"#AAA"}
                                onClick={() => approve(ProductConfigHelper.productCode(item))}/></td>
                    : <td></td>
                }

                {/* GO TO MANAGE YOUR COLLATERAL */}
                <td onClick={() => setPartner(item)}>
                  <i className="bi bi-chevron-right bi-accent pointer"></i>
                </td>
              </tr>
            })}
            </tbody>
          </table>
        </div>

        <PaginationTranslated
          totalNumber={totalNumber}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
        />
      </>
  );
}

const PartnersTableTranslated = withTranslation()(PartnersTable)
export default PartnersTableTranslated
