import React, { useState } from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import AppVersionForm from "../../partner/app-vesion-form";
import { getI18n } from "react-i18next";

function AppAdvertising({ step, setStep, updateFormData, formData }) {
  const I18n = getI18n()

  function handleSoftLockChange(version) {

    if (versionIsValid(version)) {

      if (formData.version == null) formData.version = {}
      if (formData.version.softLock == null) formData.version.softLock = {}

      formData.version.softLock = version
      updateFormData("version", formData.version)
    }
  }

  function handleHardLockChange(version) {

    if (versionIsValid(version)) {

      if (formData.version == null) formData.version = {}
      if (formData.version.hardLock == null) formData.version.hardLock = {}

      formData.version.hardLock = version
      updateFormData("version", formData.version)
    }
  }

  function versionIsValid(version) {
    return version?.newApkVersion != null && version?.oldApkVersion != null && version?.percentShareOfUpdates != null
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual">
        <div style={{ width: '450px' }}>

          {/* Hard Lock */}
          <AppVersionForm
            title={I18n.t('custom.hard_lock_title')}
            version={formData.version?.hardLock ?? {}}
            versionStartsWith={2}
            handle={handleHardLockChange}
          />


          {/* Soft Lock */}
          {formData?.version?.softLock && (
            <AppVersionForm
              title={I18n.t('custom.soft_lock_title')}
              version={formData.version?.softLock ?? {}}
              versionStartsWith={3}
              handle={handleSoftLockChange}
            />

          )}

        </div>
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />
        <NextButton step={step} setStep={setStep} />
      </section>
    </div>
  );
}

export default AppAdvertising;
