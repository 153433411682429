import DateHelper from "./date-helper";
import CollateralStateHelper from "./state-helper";
import moment from 'moment';

const CollateralHelper = {
  executorItemId: (collateral) => collateral?.executorItemId?.toUpperCase(),
  phoneNumber: (collateral) => collateral?.details?.phone,
  deviceBrand: (collateral) => collateral?.details?.brand?.toUpperCase(),
  deviceModel: (collateral) => collateral?.details?.model?.toUpperCase(),
  dueDate: (collateral, full) => collateral?.dueDate ? (full ? DateHelper.getFormattedDateTime(collateral.dueDate) : DateHelper.getFormattedDate(collateral.dueDate)) : null,
  id: (collateral) => collateral?.id,
  availableSince: (collateral, full) => collateral?.availableSince ? (full ? DateHelper.getFormattedDateTime(collateral.availableSince) : DateHelper.getFormattedDate(collateral.availableSince)) : null,
  activatedAt: (collateral, full) => collateral?.activatedAt ? (full ? DateHelper.getFormattedDateTime(collateral.activatedAt) : DateHelper.getFormattedDate(collateral.activatedAt)) : null,
  releasedAt: (collateral, full) => collateral?.releasedAt ? (full ? DateHelper.getFormattedDateTime(collateral.releasedAt) : DateHelper.getFormattedDate(collateral.releasedAt)) : null,
  state: (collateral) => collateral?.state != null ? CollateralStateHelper.translate(collateral.state) : null,
  productItemId: (collateral) => collateral?.productItemId,
  device: (collateral) => collateral?.details?.brand && collateral?.details?.model ? `${collateral.details.brand.toLowerCase()} ${collateral.details.model.toLowerCase()}` : null,
  currency: (result) => result?.data?.data['BRL']?.value,
  lockType: (collateral) => collateral?.details?.lockType?.toUpperCase(),
  lockIsScheduled: (newDueDate) => newDueDate ? moment(newDueDate).year() < CollateralHelper.infiniteYear() : false,
  infiniteYear: () => 2100,
  userId: (collateral) => collateral?.details?.userId,
  isKnoxGuard: (collateral) => collateral?.executor === 4,
  lockMessage: (collateral) => collateral?.details?.lockMessage ?? '',
};

export default CollateralHelper;
