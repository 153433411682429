import { getI18n } from "react-i18next";
import DateHelper from "./date-helper";

const KnoxDevicesHelper = {

  deviceStates: () => {
    return [
      { value: 'ALL', label: getI18n().t('knox_devices.state_all') },
      { value: 'PENDING', label: getI18n().t('knox_devices.state_pending') },
      { value: 'WAITING_VERIFICATION', label: getI18n().t('knox_devices.state_waiting_verification') },
      { value: 'VERIFIED', label: getI18n().t('knox_devices.state_verified') },
      { value: 'DELETED', label: getI18n().t('knox_devices.state_deleted') }
    ];
  },

  createdAt(item) {
    return item
      && item.createdAt
      && DateHelper.getFormattedDateTime(item.createdAt);
  },

  state(item) {
    return item
      && item.state;
  },

  stateDescription(item) {
    switch (item.state) {
      case 0:
        return getI18n().t('knox_devices.state_pending');
      case 1:
        return getI18n().t('knox_devices.state_waiting_verification');
      case 2:
        return getI18n().t('knox_devices.state_verified');
      case 3:
        return getI18n().t('knox_devices.state_deleted');
      default:
        return "N/A";
    }
  },

  imei(item) {
    return item
      && item.imei;
  },

  userId(item) {
    return item
      && item.userId;
  },

  productCode(item) {
    return item
      && item.productCode;
  },

  validateIMEI(imei) {
    imei = imei.replace(/\D/g, '');

    if (imei.length !== 15) {
      return false;
    }

    let sum = 0;
    let shouldDouble = false;

    for (let i = imei.length - 1; i >= 0; i--) {
      let digit = parseInt(imei.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10 === 0);
  }
}

export default KnoxDevicesHelper;
