import React from 'react';
import Attribute from "./attribute";
import IconAndroid from "../../images/icons/android.svg";
import IconPhone from "../../images/icons/phone.svg";
import IconApp from "../../images/icons/app.svg";
import IconPackage from "../../images/icons/box.svg";
import IconRocket from "../../images/icons/rocket.svg";
import IconCalendar from "../../images/icons/calendar.svg";
import FadeIn from "react-fade-in";

function AppSpecifications(props) {

  return (
    <FadeIn>
      <section className="app-information">
        {/* Requirements */}
        <div className="card">
          <h4 className="app-h4">Requirements</h4>
          <div className="app-silver-box">
            <Attribute
              icon={IconAndroid}
              content={props.os}
            />
            <Attribute
              icon={IconPhone}
              content={props.brands}
            />
          </div>
        </div>
        {/* More information */}
        <div className="card">
          <h4 className="app-h4">More information</h4>
          <div className="app-silver-box">
            <Attribute
              icon={IconApp}
              content={"Name: "+ props.name}
            />
            <Attribute
              icon={IconPackage}
              content={"Size: "+ props.size}
            />
          </div>
        </div>
        {/* Updates */}
        <div className="card">
          <h4 className="app-h4">Updates</h4>
          <div className="app-silver-box">
            <Attribute
              icon={IconRocket}
              content={"Version: "+ props.version}
            />
            <Attribute
              icon={IconCalendar}
              content={"Last Updated: "+ props.lastUpdate}
            />
          </div>
        </div>
      </section>
    </FadeIn>
  );
}

export default AppSpecifications;
