import React from 'react';

function PreviousButton({ step, setStep }) {
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  return (
    <button className="outline-btn" onClick={handlePreviousStep}>Previous</button>
  );
}

export default PreviousButton;
