import i18n from "i18next"

const CollateralStateHelper = {

    translate: (state) => {
        if (state == null || state === '') return '--';

        switch (state) {
            case 0:
                return i18n.t('account_info.not_available');
            case 1:
                return i18n.t('account_info.available');
            case 2:
                return i18n.t('account_info.claimed');
            case 3:
                return i18n.t('account_info.released');
            case 4:
                return i18n.t('account_info.active');
            case 5:
                return i18n.t('account_info.judicially_released');
            default:
                return state;
        }
    },
};

export default CollateralStateHelper;
