import React, {useEffect, useState} from 'react';
import {UserAgent} from "react-useragent";
import QRCode from "react-qr-code";
import QRCodeLink from "qrcode";

function AppDownload(props) {
  const [qrCodeLink, setQrCodeLink] = useState();

  useEffect(() => {
    handleLink(props.apk)
  }, [props.apk]);

  function handleLink(url) {
    QRCodeLink.toDataURL(url, {
      width: 600,
      margin: 3
    }, function (err, url) {
      setQrCodeLink(url);
    })
  }

  return (
    <section className="app-download">
      <div className="app-display">
        <section className="app-icon">
          <img alt="App icon" src={props.logo}/>
        </section>
        <section>
          {/* Application name */}
          <h2 className="app-name">{props.name}</h2>
          {/* Application Category */}
          <h3 className="app-category">{props.category}</h3>
        </section>
      </div>

      <UserAgent>
        {({ ua }) => {
          return ua.mobile ?
            <div className="download-button-mobile-container">
              <a href={props.apk} style={{background: props.color}} className="download-button" download>
                Download
                <i className="bi bi-download"></i>
              </a>
            </div>
            :
            <div className="qr-code-container">
              <QRCode className="app-qr-code" value={props.apk}/>
              <a href={qrCodeLink} style={{background: props.color}} className="download-button download-link" download={'qrcode.png'}>
                Download
                <i className="bi bi-download"></i>
              </a>
            </div>
        }}
      </UserAgent>
    </section>
  );
}

export default AppDownload;
