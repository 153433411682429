import {Chart} from "react-google-charts";
import React, {useEffect, useState} from "react";
import Dropdown from 'react-dropdown';
import {withTranslation} from "react-i18next";
import CollateralService from "../../service/collateral-service";
import * as Authentication from '../../utils/helpers/authentication-helper';
import PartnerService from '../../service/partner-config-service';
import ProductConfigHelper from '../../utils/helpers/product-config-helper';

function ChartCountyByStatus(props) {
  const {t} = props;
  const [data, setData] = useState([])
  const [processedData, setProcessedData] = useState([])
  const isAdmin = Authentication.isAdmin()
  const [isDataValid, setIsDataValid] = useState(data.length > 0 && data[0].length > 0);
  const [productCodes, setProductCodes] = useState([]);
  const [productCode, setProductCode] = useState(!isAdmin ? ProductConfigHelper.getProductCode() : 'demo');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemsOption = [t('billing.simplified'), "Total"];
  const options = {title: t('billing.total_by_status')};
  const [visibilityOption, setVisibilityOption] = useState(itemsOption[0])

  /* Const */
  const mockupData = [
    [t('account_info.status'), t('billing.quantity')],
    [t('billing.active'), 1],
    [t('billing.released'), 0],
    [t('billing.locked'), 0],
  ];

  useEffect(() => {
    setVisibilityOption(itemsOption[0]);
  }, [itemsOption, t]);

  /* Load Data */
  useEffect(() => {
    const fetchData = async () => {
      if(isAdmin && productCode === '') return;
      const result = await CollateralService.countByStatus(productCode)
      setData(result.data)
    }
    fetchData()
  }, [isAdmin, productCode])

  useEffect(() => {
    const loadPartners = async () => {
      try {
        const result = await PartnerService.findAllPartners(0, 500, 'DESC');
        if(result && result.data.content.length > 0) {
          const filteredProductCodes = result.data.content
            .map((item, index) => ({ value: index, label: item.productCode }));

          filteredProductCodes.sort((a, b) => a.label.localeCompare(b.label));
          setProductCodes(filteredProductCodes);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadPartners();
  }, []);

  /* Apply Filter */
  useEffect(() => {
    const mock = [
      [t('account_info.status'), t('billing.quantity')],
      [t('billing.active'), 1]
    ];

    const translateStatus = (status) => {
      const statusMap = {
        'active': t('billing.active'),
        'released': t('billing.released'),
        'locked': t('billing.locked'),
      };
      return statusMap[status.toLowerCase()] || status;
    };

    const processData = (requiredStatuses) => {
      return [
        [t('account_info.status'), t('billing.quantity')],
        ...requiredStatuses.map(status => {
          const found = data.find(item => item[0] === status);
          return found ? [translateStatus(found[0]), found[1]] : [translateStatus(status), 0];
        })
      ];
    };

    const requiredStatuses = visibilityOption === "Total" ? ['Active', 'Locked', 'Released'] : ['Active', 'Locked'];
    const processedData = processData(requiredStatuses);

    const isValid = checkDataValidity(processedData);
    setProcessedData(isValid ? processedData : mock);

  }, [data, t, visibilityOption]);

  const handlePartnerChange = (event) => {
    setProductCode(event.label);
  };

  function checkDataValidity(data) {
    let isDataValid = false;

    for (let i = 1; i < data.length; i++) {
      if (data[i][1] > 0) {
        isDataValid = true;
        break;
      }
    }

    setIsDataValid(isDataValid);
    return isDataValid;
  }

  /* Render Chart */
  return (
    <div className="group-components">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Dropdown className="form-select" options={itemsOption} onChange={(event) => setVisibilityOption(event.value)}
                  value={visibilityOption} placeholder={t('billing.select_option')} />

        <div>
          <p className="silver label-same-size"
             style={{ margin: 0, display: isAdmin ? 'visible' : 'none' }}>{t('billing.partner')}</p>
        </div>

        {isAdmin ? (
            <div className="input-container" style={{ width: '220px' }}>
              <Dropdown
                className="form-select-without-margin-horizontal"
                options={productCodes}
                onChange={handlePartnerChange}
                value={productCode}
                placeholder={t('billing.select')}
              /></div>) :
          ''
        }
      </div>

        {isDataValid ? (
          <Chart
            className="chart"
            chartType="PieChart"
            data={processedData}
            options={options}
            width={"100%"}
            height={"400px"}
          />
        ) : (
          <div>
            <Chart
              className="chart"
              chartType="PieChart"
              data={mockupData}
              options={options}
              width={"100%"}
              height={"400px"}
            />
            <div className="alert">{t('billing.no_data_found')}</div>
          </div>
        )}
      </div>
      );
      }

      const ChartCountyByStatusTranslated = withTranslation()(ChartCountyByStatus)
      export default ChartCountyByStatusTranslated
