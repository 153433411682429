import React from 'react';
import LoadingComponent from 'components/ui/loading/loading-component';
import CollateralsTable from 'components/collaterals-table/collaterals-table';
import {withTranslation} from "react-i18next";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {t} = this.props;
    const {loading} = this.state;
    if (loading) return <LoadingComponent/>
    else {
      return <>
        <div className="demo-container">
          <CollateralsTable/>
        </div>
      </>
    }
  }
}

const PartnerHomeTranslated = withTranslation()(Home)

export default PartnerHomeTranslated
