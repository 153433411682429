import React from "react";

function IconInput(props) {
  const formInput = {
    maxWidth: '250px',
  }

  return (
    <div style={formInput} className="form-input">
      <input onChange={props.onChange} type={props.type} className="invisible-input" placeholder={props.placeholder}/>
      <button className="invisible-button" onClick={props.onClick}>
        <i className={props.icon}></i>
      </button>
    </div>
  )
}

export default IconInput
