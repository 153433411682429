import API from 'config/api';

const KnoxDevicesService = {

  getTemplate: (action) => {
    return API.get(`/bulk-actions/template?action=${action}`,  {
      responseType: 'blob',
    });
  },
  submitAction: (action, params) => {
    return API.post(`/bulk-actions/${action}`, params);
  }
};

export default KnoxDevicesService;
