import React from 'react';
import { withTranslation } from "react-i18next";
import ImageHelper from "../../../utils/helpers/image-helper";
import ToastHelper from '../../../../utils/helpers/toast-helper';

class AppIconForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appIcon: props.appIcon,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="app-icon-example-container">
        <p className="label">{t('custom.app_icon')}</p>
        <label className="label-tooltip">432 x 432</label>

        <div onClick={() => { this.uploadAppIcon.click() }}>
          {this.state.appIcon ? (
            <div className="dimension-app-icon-example">
              <img className="dimension-app-icon-example-image" src={ImageHelper.verifyFormat(this.state.appIcon)}  alt="icon"/>
            </div>
          ) : (
            <div className="dimension-app-icon-example">
              <p className="image-icon-plus">+</p>
            </div>
          )}
        </div>

        <input
          type='file'
          accept="image/png"
          ref={(ref) => this.uploadAppIcon = ref}
          onChange={this.handleAppIconChange}
          style={{ display: 'none' }} />

      </div>
    );
  }

  handleAppIconChange = (event) => {
    event.preventDefault()
    const { t } = this.props;
    const width = 432
    const height = 432

    var file
    if ((file = event.target.files[0])) {
      this.setState({ appIcon: file });
      this.props.handle(file)
    }
  };
}

const AppIconFormTranslated = withTranslation()(AppIconForm)

export default AppIconFormTranslated
