import React from 'react';
import { getI18n, withTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import MultipartForm from "../multipart/multipart-form";
import PartnerHelper from "../../../../utils/helpers/partner-helper";
import PartnerActionHelper from "../../../../utils/helpers/partner-action-helper";

class UpdatePartner extends React.Component {
  I18n = getI18n();

  constructor(props) {
    super(props);
    this.state = {
      /* Basic information */
      name: PartnerHelper.partnerName(props),
      email: PartnerHelper.partnerEmail(props),
      activationFee: 0,
      maintenanceFee: 0,

      /* App visual */

      /* Icon */
      appIcon: PartnerHelper.appLogo(props),
      appIconMdpiUrl: PartnerHelper.appIconMdpiUrl(props),
      appIconHdpiUrl: PartnerHelper.appIconHdpiUrl(props),
      appIconXhdpiUrl: PartnerHelper.appIconXhdpiUrl(props),
      appIconXxhdpiUrl: PartnerHelper.appIconXxhdpiUrl(props),
      appIconXxxhdpiUrl: PartnerHelper.appIconXxxhdpiUrl(props),
      updateAppIcon: false,
      /* End Icon */

      logo: PartnerHelper.logo(props),
      updateLogo: false,
      backgroundColor: PartnerHelper.backgroundColor(props),

      /* App content */
      phoneNumber: PartnerHelper.phoneNumber(props),
      helpPage: PartnerHelper.helpPage(props),
      lockMessage: PartnerHelper.lockMessage(props),

      /* Advanced settings */
      lockType: PartnerHelper.lockType(props),
      webhook: PartnerHelper.webhook(props),
      webhookUrlCustomer: PartnerHelper.webhookUrlCustomer(props),
      appConfiguration: PartnerHelper.appConfiguration(props),
      knoxGuard: PartnerHelper.knoxGuard(props),

      action: PartnerActionHelper.UPDATE,
      partnerCode: PartnerHelper.partnerCode(props),

      /* Advertisements */
      ads: PartnerHelper.ads(props),

      /* Version */
      version: PartnerHelper.version(props),

      /* Temporary Unlock */
      temporaryUnlock: PartnerHelper.temporaryUnlock(props),

      /* Push */
      push: PartnerHelper.push(props),
    };
  }

  updateFormData = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {

    return (
      <>
        <MultipartForm
          props={this.props}
          updateFormData={this.updateFormData}
          formData={this.state}
        />
      </>
    );
  }
}

const UpdatePartnerTranslated = withTranslation()(UpdatePartner)

export default UpdatePartnerTranslated
