import React from "react";

function Button(props) {

  const isColorLight = (color) => {
    // Parse to RGB
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;

    // Calculates the brightness of the color using the formula Y = 0.299*R + 0.587*G + 0.114*B
    const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Returns true if the color is light (brightness greater than 0.5), otherwise returns false
    return brightness > 0.5;
  };

  // Determines the text color based on the background color
  const backgroundColor = props.color || "#000";
  const textColor = isColorLight(backgroundColor) ? "black" : "white";

  return (
    <button className="short-button" style={{ backgroundColor, color: textColor }} onClick={props.onClick}>
      {props.label}
    </button>
  );
}

export default Button;
