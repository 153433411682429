import React from 'react';
import { Provider } from 'react-redux';
import store from 'store/application-store';
import StorageHelper from 'utils/helpers/storage-helper';
import ApplicationRouter from 'navigation/application-router';

// Persisting the redux into browser storage
store.subscribe(() => {
  StorageHelper.saveStateToLocalStorage(store.getState());
});

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ApplicationRouter />
      </Provider>
    </div>
  );
}

export default App;
