import React from "react";

const Modal = ({
                 clickedImg,
                 setClickedImg,
                 handelRotationRight,
                 handelRotationLeft
               }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <div className="overlay dismiss" onClick={handleClick}>
        <img src={clickedImg} alt="bigger pic" />
        <span className="dismiss" onClick={handleClick}>
          X
        </span>
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
          <div>
            <i className="bi bi-arrow-left"></i>
          </div>
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
          <div>
            <i className="bi bi-arrow-right"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
