import API from 'config/api';

const KnoxDevicesService = {

  create: (body) => {
    return API.post(`/knox-device/create`, body);
  },

  findAllDevices: (page, size, direction) => API.get(
    `knox-device/devices?page=${page}&size=${size}&direction=${direction}`
  ),

  findByUserId: (page, size, direction, userId) => API.get(
    `knox-device/find-by-user-id?page=${page}&size=${size}&direction=${direction}&userId=${userId}`
  ),

  findByState: (page, size, direction, state) => API.get(
    `knox-device/find-by-state?page=${page}&size=${size}&direction=${direction}&state=${state}`
  ),

  findByIMEI: (page, size, direction, imei) => API.get(
    `knox-device/find-by-imei?page=${page}&size=${size}&direction=${direction}&imei=${imei}`
  ),

};

export default KnoxDevicesService;
