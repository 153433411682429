import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import AuthenticationService from 'service/authentication-service';
import routes from 'navigation/routes';
import logo from 'assets/images/logo.png';

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.match.params.username,
      validationCode: props.match.params.validationCode,
      loading: false,
      newPassword: '',
      repeatPassword: '',
      messageError: '',
      loggedIn: false,
    };
  }

  onNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  onRepeatPasswordChange = (event) => {
    this.setState({ repeatPassword: event.target.value });
  };

  changePassword = () => {
    const { username, validationCode, newPassword, repeatPassword } =
      this.state;
    this.setState({ messageError: '', loading: true });
    if (newPassword !== repeatPassword) {
      this.setState({
        messageError: 'As duas senhas devem ser iguais.',
        loading: false,
      });
    } else {
      AuthenticationService.changePassword(
        username,
        validationCode,
        newPassword
      ).then((response) => {
        if (response.status === 200) {
          NotificationManager.success('Senha alterada com sucesso');
          window.location = routes.LOGIN;
        } else {
          NotificationManager.error(
            'Erro ao alterar a senha. Tente novamente.'
          );
        }
      });
    }
  };

  render() {
    const { newPassword, repeatPassword } = this.state;

    return (
      <div
        style={{
          maxWidth: '500px',
          margin: '0 auto',
          background: 'white',
        }}
      >
        <div className="p-3 text-center rounded-top background-primary">
          <img src={logo} alt="Logo" className="logo-small" />
        </div>
        <Form id="loginForm" className="border p-3 shadow-sm rounded-bottom">
          <Form.Group controlId="formBasicLogin">
            <Form.Label>Nova senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Nova senha"
              value={newPassword}
              onChange={this.onNewPasswordChange}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Repita a nova senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Repita a nova senha"
              value={repeatPassword}
              onChange={this.onRepeatPasswordChange}
            />
          </Form.Group>

          {this.state.messageError ? (
            <section id="errors" className="text-center mb-2">
              <div className="text-danger">{this.state.messageError}</div>
            </section>
          ) : (
            ''
          )}

          <div className="text-center">
            <Button
              variant="dark"
              disabled={this.state.loading}
              id="btn-login"
              onClick={this.changePassword}
            >
              {this.state.loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
              )}{' '}
              Entrar
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default NewPassword;
