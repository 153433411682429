import React from 'react';
import { withTranslation } from "react-i18next";

class AppPushForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      push: props.value ?? {}
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">

        <div className="two-col" onClick={this.handleEnablePushyChange}>
          <label>Pushy</label>
          <div >
            {this.state.push.pushyEnabled ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="two-col" onClick={this.handleEnableMqttChange}>
          <label>MQTT</label>
          <div >
            {this.state.push.mqttEnabled ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        {this.state.push.mqttEnabled ? (
          <div>
            <div style={{ marginTop: "20px" }}>
              <label>Client ID</label>
              <input
                type="text"
                className="form-input"
                value={this.state.push.mqttClientId}
                placeholder="Optional"
                onChange={this.handleMqttClientIdChange}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <label>Client Secret</label>
              <input
                type="text"
                className="form-input"
                value={this.state.push.mqttClientSecret}
                placeholder="Optional"
                onChange={this.handleMqttClientSecretChange}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <label>Client Server URL</label>
              <input
                type="text"
                className="form-input"
                value={this.state.push.mqttClientServer}
                placeholder="Optional"
                onChange={this.handleMqttClientServerChange}
              />
            </div>
          </div>

        ) :
          <div style={{ marginBottom: "30px" }} />
        }
      </div>
    );
  }

  handleMqttClientServerChange = (event) => {

    let obj = this.state.push
    obj.mqttClientServer = event.target.value

    this.setState({
      push: obj
    });
    this.props.handle(obj)
  };

  handleMqttClientIdChange = (event) => {

    let obj = this.state.push
    obj.mqttClientId = event.target.value

    this.setState({
      push: obj
    });
    this.props.handle(obj)
  };

  handleMqttClientSecretChange = (event) => {

    let obj = this.state.push
    obj.mqttClientSecret = event.target.value

    this.setState({
      push: obj
    });
    this.props.handle(obj)
  };

  handleEnableMqttChange = (event) => {

    let isChecked = !this.state.push.mqttEnabled

    let obj = this.state.push
    obj.mqttEnabled = isChecked

    this.setState({
      push: obj
    });

    this.props.handle(obj)
  }

  handleEnablePushyChange = (event) => {

    let isChecked = !this.state.push.pushyEnabled

    let obj = this.state.push
    obj.pushyEnabled = isChecked

    this.setState({
      push: obj
    });

    this.props.handle(obj)
  }
}

const AppPushFormTranslated = withTranslation()(AppPushForm)

export default AppPushFormTranslated
